<template>
    <div class="comment" :style="isApp ? 'padding-top: 1.3rem' : ''">
        <div class="app_title_b">
            <van-sticky>
                <van-nav-bar title="评论" left-arrow @click-left="fLeft" />
            </van-sticky>

        </div>
        <div class="zhichi">
            <div class="zhichi_top">
                <div :class="oCPData.zhiData.yesNo && oCPData.zhiData.ranks == 'HOST' ? 'zhichi_top_r' : 'zhichi_top_l'" @click="supportMatchFun('HOST')">支持</div>
                <div class="zhichi_top_z">
                    <div>{{oCPData.zhiData.hostShortName}}</div>
                    <img src="~@/assets/icon/icon-011.png" alt="">
                    <div>{{oCPData.zhiData.guestShortName}}</div>
                </div>
                <div :class="oCPData.zhiData.yesNo && oCPData.zhiData.ranks == 'GUEST' ? 'zhichi_top_r' : 'zhichi_top_l'" @click="supportMatchFun('GUEST')">支持</div>
            </div>
            <div class="jiezhi">
                <div class="jiezhi_l"></div>
                <div class="jiezhi_z">{{oCPData.zhiData.deadlineStr}}截止 {{oCPData.zhiData.gameNo}}{{oCPData.zhiData.gameName}}</div>
                <div class="jiezhi_r"></div>
            </div>
            <div class="zhichi_img_box">
                <div :class="oCPData.zhiData.yesNo && oCPData.zhiData.ranks == 'HOST' ? 'zhichi_img_box_l1' : 'zhichi_img_box_l'" :style="oCPData.zhu"></div>
                <img src="~@/assets/images/new/new_032.png" alt="" class="zhichi_img_box_z" v-if="oCPData.zhiData.yesNo && oCPData.zhiData.ranks == 'GUEST'">
                <img src="~@/assets/images/new/new_038.png" alt="" class="zhichi_img_box_z" v-else-if="oCPData.zhiData.yesNo && oCPData.zhiData.ranks == 'HOST'" >
                <img src="~@/assets/images/new/new_037.png" alt="" class="zhichi_img_box_z" v-else >
                <div :class="oCPData.zhiData.yesNo && oCPData.zhiData.ranks == 'GUEST' ? 'zhichi_img_box_r' : 'zhichi_img_box_r1'" :style="oCPData.ke"></div>
            </div>
            <div class="zhichi_boot">
                <div class="zhichi_boot_l">
                    <img src="~@/assets/images/new/new_036.png" alt="" v-if="oCPData.zhiData.yesNo && oCPData.zhiData.ranks == 'HOST'">
                    <img src="~@/assets/images/new/new_033.png" alt="" v-else>
                    <div :style="oCPData.zhiData.yesNo && oCPData.zhiData.ranks == 'HOST' ? 'color: #E2335D;' : 'color: #FFC4D2;' ">{{oCPData.zhiData.hostSupportNum}}</div>
                </div>
                <div class="zhichi_boot_r">
                    <div :style="oCPData.zhiData.yesNo && oCPData.zhiData.ranks == 'GUEST' ? 'color: #E2335D;' : 'color: #FFC4D2;' ">{{oCPData.zhiData.guestSupportNum}}</div>
                    <img src="~@/assets/images/new/new_034.png" alt="" v-if="oCPData.zhiData.yesNo && oCPData.zhiData.ranks == 'GUEST'">
                    <img src="~@/assets/images/new/new_035.png" alt="" v-else>
                </div>
            </div>
        </div>
        <div class="conter">
            <div class="conter_title">
                全部留言  {{oCPData.msgTotal}}
            </div>
            <div class="conter_box_box">
                <div class="conter_box" v-for="(item,index) in oCPData.msgData" :key="index">
                    <div class="conter_box_l">
                        <img :src="item.commentPic" alt="" v-if="item.commentPic" @click="toZhuye(item.commentRoleId)">
                        <img src="~@/assets/images/new/new_019.png" alt="" v-else @click="toZhuye(item.commentRoleId)">
                    </div>
                    <div class="conter_box_r">
                        <div class="conter_box_r_top">
                            <div class="conter_box_r_top_name">
                                {{item.commentRoleName}}
                            </div>
                            <div class="conter_box_r_top_zan">
                                <img src="~@/assets/images/new/ic_017.png" alt="" v-if="item.agreeSign" @click="zanFan1(false,item)">
                                <img src="~@/assets/images/new/ic_016.png" alt="" v-else @click="zanFan1(true,item)">
                                <div>{{item.commentAgreeNum}}</div>
                            </div>
                        </div>
                        <div class="conter_box_lz" v-if="item.replyText" @click="toPlDetail(item)" >
                            <template v-if="item.replyDelSign">
                                <span style="color: #383838;">{{item.replyRoleName}}：</span>原文内容已删除
                            </template>
                            <template v-else>
                                <span style="color: #383838;">{{item.replyRoleName}}：</span>{{item.replyText}}
                            </template>

                        </div>
                        <div class="conter_box_r_con"  @click="toPlDetail(item)">
                            {{item.commentText}}
                        </div>
                        <div class="conter_box_r_bot">
                            <div class="conter_box_r_bot_shi">
                                {{item.commentTime}}
                            </div>
                            <div class="conter_box_r_bot_del" style="color: #FF7671;" v-if="item.commentRoleId == oCPData.roleId" @click="deletePl(item)">
                                删除
                            </div>
                            <div class="conter_box_r_bot_del" style="color: #FF7671;" v-else @click="jvbaoK(item)">
                                举报
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wsj_xob" v-if="oCPData.msgData.length == 0">
                    <img src="~@/assets/images/img_021.png" alt="" >
                    <div>暂无数据</div>
                </div>
            </div>

        </div>
        <div class="bot">
            <div class="bot_box">
                <div class="bot_l" @click="huifu">
                    请输入评论内容
                </div>
                <div class="bot_r">
                    发布
                </div>
            </div>
        </div>
        <!--		举报弹出层-->
        <van-popup v-model:show="oCPData.jbShow" position="bottom" class="tan_put"   closeable :overlay="oCPData.jbBverlay">
            <div class="jb_box">
                <div class="jb_item" v-for="(item,index) in oCPData.jbData" :key="index" @click="jvbao(item)">
                    {{item.informTypeName}}
                </div>
            </div>
        </van-popup>

        <!--        评论弹出层-->
        <van-popup v-model:show="oCPData.show" position="bottom" class="tan_put" :style="{ height: '1.6rem' }"  :overlay="oCPData.overlay">
            <div class="tan_put_box">
                <input type="text" placeholder="请输入评论内容" v-model="oCPData.inputData" @input="showBut">
                <div :style="oCPData.inputShow ? 'background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);' : ''" @click="pinglunFun">发布</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import {
        listComment,
        saveAgree,
        listAccuseInfo,
        accuseReply,
        saveComment,
        delReply,
        showSupportNum,
        supportMatch
    } from '@/api/plan.js';
    import {
        uaFun
    } from "@/utils/public.js";
    import {
        focusOn,
        focusCancel
    } from '@/api/home.js';
    import {useStore} from "vuex";
    // import {useRoute, useRouter} from "vue-router";
    import {
        fnIsHasHistory
    } from "@/utils/router.js";
    import {onActivated, reactive} from "vue";
    import {useRoute,useRouter} from "vue-router";
    import {Toast} from "vant";
    export default {
        name: "detailMatch",
        setup () {
            const isApp = uaFun();
            const store = useStore();
            // const route = useRoute();
            const route = useRoute();
            const route_query = route.query;
            const UserInfo = store.state.userInfo.userInfo;
            const router = useRouter();
            const oCPData = reactive({
                msgData:[],
                msgTotal:0,
                agreeList:[],
                isJb:false,
                jbShow:false,
                jbData:[],
                Titem:{},
                show:false,
                inputData:'',
                overlay:false,
                inputShow:false,
                TentityId:"",
                islouzhu:false,
                roleId:UserInfo.roleId,
                zhiData:{},
                zhu:'width:50%',
                ke:'width:50%'
            })
            console.log(route_query.commentId);
            console.log(route_query);
            // 获取消息列表

            const msgFun1 = () =>{
                Toast({
                    type:'loading'
                });
                listComment({
                    "current": 1,
                    "entityId": route_query.entityId,
                    "jumpType": "NORMAL",
                    "roleId": UserInfo.roleId,
                    "size": 20,
                    "type": "MATCH",
                    "userId": UserInfo.userId
                }).then(res=>{
                    oCPData.msgData = res.result.records
                    oCPData.msgTotal = res.result.total
                })
            };
            const toZhuye = (Id) =>{
                // 跳转到方案详情
                router.push({
                    path: '/zhuye',
                    query: {
                        id: Id
                    }
                })
            }

            const zanFan1 = (e,item) =>{
                saveAgree({
                    agreeSign:e,
                    entityId:item.commentId,
                    gatherAgreeRoleId:item.commentRoleId,
                    gatherAgreeUserId:item.commentUserId,
                    roleId:UserInfo.roleId,
                    type:'COMMENT',
                    userId:UserInfo.userId
                }).then(()=>{
                    msgFun1()
                })
            }

            const jvbaoK = (e) =>{
                oCPData.Titem = e
                listAccuseInfo().then(res=>{
                    oCPData.jbData = res.result
                    oCPData.jbShow = true
                })

            }
            const focusCancelFun = () =>{
                focusCancel({
                    focusRoleId:oCPData.touMsgData.commentRoleId,
                    roleId:UserInfo.roleId,
                }).then(res=>{
                    console.log(res);


                })
            }
            const focusOnFun = () =>{
                focusOn({
                    focusRoleId:oCPData.touMsgData.commentRoleId,
                    roleId:UserInfo.roleId,
                }).then(res=>{
                    console.log(res);
                    if(res.success){
                        // msgFun()
                    }

                })
            }
            const jvbao = (e) =>{
                accuseReply({
                    accuseInfo:e.informTypeName,
                    accuseInfoId:e.informTypeId,
                    beAccusedRoleId:oCPData.Titem.commentRoleId,
                    beAccusedUserId:oCPData.Titem.commentUserId,
                    entityId:oCPData.Titem.commentId,
                    roleId:UserInfo.roleId,
                    type:'COMMENT',
                    userId:UserInfo.userId
                }).then(()=>{
                    oCPData.jbShow = false
                    oCPData.jbBverlay = false
                    oCPData.isJb = false
                    Toast('举报成功');
                    msgFun1()
                })
            }
            const showBut = () =>{
                if(oCPData.inputData && oCPData.inputData.length > 0){
                    oCPData.inputShow = true
                }else {
                    oCPData.inputShow = false
                }

            }
            const huifu = () =>{
                oCPData.show = true
                oCPData.inputData = ""
                oCPData.overlay= true
                // oCPData.TentityId = oCPData.touMsgData.commentId
                oCPData.islouzhu = true

            }

            const huifu2 = (item) =>{
                oCPData.show = true
                oCPData.overlay= true
                oCPData.inputData = ""
                oCPData.TentityId = item.replyId
                oCPData.islouzhu = false

            }
            const pinglunFun = () =>{
                saveComment({
                    "commentText": oCPData.inputData,
                    "entityId":route_query.entityId,
                    "roleId": UserInfo.roleId,
                    "type": "MATCH",
                    "userId": UserInfo.userId
                }).then(()=>{
                    Toast({
                        type:'loading'
                    });
                    msgFun1()
                    oCPData.show = false
                    oCPData.overlay= false
                    oCPData.inputData = ""
                    oCPData.islouzhu = false
                })

            }
            const deletePl = (e) =>{
                delReply({
                    replyId:e.commentId,
                    "roleId": UserInfo.roleId,
                    "userId": UserInfo.userId
                }).then(()=>{
                    msgFun1()
                })
            }
            const showSupportNumFun = () =>{
                Toast({
                    type:'loading'
                });
                showSupportNum({
                    matchId:route_query.entityId,
                    type:route_query.type,
                    roleId:UserInfo.roleId
                }).then(res=>{
                    oCPData.zhiData = res.result
                    oCPData.zhu ='width:' + ((res.result.hostSupportNum / (res.result.hostSupportNum - (- res.result.guestSupportNum))) * 100) + '%'
                    oCPData.ke ='width:' + ((res.result.guestSupportNum / (res.result.hostSupportNum - (- res.result.guestSupportNum))) * 100) + '%'

                })
            }
            const supportMatchFun = (e) =>{
                if(oCPData.zhiData.yesNo && e != oCPData.zhiData.ranks){
                    Toast("请先取消当前支持")
                }else {
                    supportMatch({
                        matchId:route_query.entityId,
                        ranks:e,
                        type:route_query.type,
                        roleId:UserInfo.roleId,
                        userId:UserInfo.userId

                    }).then(()=>{
                        showSupportNumFun()
                    })
                }

            }
            const toPlDetail = (e) =>{
                router.push({
                    path: '/comment',
                    query: {
                        commentId:e.commentId,
                        planId:route_query.entityId,
                        type:'MATCH'
                    }
                })
            }
            /*
           * fLeft van-nav-bar 左侧点击事件
           */
            const fLeft = () => {
                fnIsHasHistory("/plan");
            };
            onActivated(() => {
                msgFun1()
                showSupportNumFun()
            });

            msgFun1()
            showSupportNumFun()
            return{
                fLeft,
                showBut,
                supportMatchFun,
                toPlDetail,

                deletePl,
                oCPData,
                toZhuye,
                pinglunFun,
                huifu,
                huifu2,
                isApp,
                zanFan1,
                focusCancelFun,
                jvbaoK,
                focusOnFun,
                jvbao,

            }
        }
    }
</script>

<style scoped lang="scss">
    .comment{
        width: 100%;
        /*height: 100%;*/
        padding-bottom: 2.44rem;
        box-sizing: border-box;
        background: #F1F2F7;
        .zhichi{
            width: calc(100% - 0.64rem);
            height: 3.52rem;
            margin: 0 auto;
            background: #FAFAFA;
            border-radius: 0.213rem 0.213rem 0px 0px;
            padding: 0.64rem 0.373rem 0.48rem;
            box-sizing: border-box;
            .zhichi_top{
                width: 100%;
                height: 0.587rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0.067rem;
                .zhichi_top_l{
                    width: 1.387rem;
                    height: 0.533rem;
                    border-radius: 0.267rem;
                    border: 2px solid #E2335D;
                    font-size: 0.32rem;
                    font-weight: 400;
                    color: #E2335D;
                    line-height: 0.533rem;
                    text-align: center;
                }
                .zhichi_top_z{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    div{
                        font-size: 0.427rem;
                        font-weight: 500;
                        color: #383838;
                    }
                    img{
                        width: 0.4rem;
                        height: 0.4rem;
                        margin: 0 0.293rem;
                    }
                }
                .zhichi_top_r{
                    width: 1.387rem;
                    height: 0.533rem;
                    background: #E2335D;
                    border-radius: 0.267rem;
                    border: 0.027rem solid #E2335D;
                    font-size: 0.32rem;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 0.533rem;
                    text-align: center;
                }
            }
            .jiezhi{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 0.32rem;
                font-weight: bold;
                color: #979797;
                margin-bottom: 0.267rem;
            }
            .zhichi_img_box{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0.16rem;
                .zhichi_img_box_l{
                    width: calc((100% - 1.76rem)/2);
                    height: 0.16rem;
                    background: #FFC4D2;
                    border-radius: 0.107rem 0.027rem 0.027rem  0.107rem;
                }
                .zhichi_img_box_l1{
                    background: #E2335D;
                    width: calc((100% - 1.76rem)/2);
                    height: 0.16rem;
                    border-radius: 0.107rem 0.027rem 0.027rem  0.107rem;
                }
                img{
                    width: 1.76rem;
                    height: 0.533rem;
                }
                .zhichi_img_box_r{
                    width: calc((100% - 1.76rem)/2);
                    height: 0.16rem;
                    background: #E2335D;
                    border-radius: 0.027rem 0.107rem 0.107rem 0.027rem;
                }
                .zhichi_img_box_r1{
                    width: calc((100% - 1.76rem)/2);
                    height: 0.16rem;
                    background: #FFC4D2;
                    border-radius: 0.027rem 0.107rem 0.107rem 0.027rem;
                }
            }
            .zhichi_boot{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .zhichi_boot_l{
                    display: flex;
                    align-items: center;
                    div{
                        font-size: 0.48rem;
                        font-weight: bold;
                        color: #FFC4D2;
                    }
                    img{
                        width: 0.4rem;
                        height: 0.427rem;
                        margin-right: 0.133rem;
                    }
                }
                .zhichi_boot_r{
                    display: flex;
                    align-items: center;
                    div{
                        font-size: 0.48rem;
                        font-weight: bold;
                        color: #E2335D;
                    }
                    img{
                        width: 0.4rem;
                        height: 0.427rem;
                        margin-left: 0.133rem;
                    }
                }
            }
        }
        .conter{
            height: 13.84rem;
            background: #FFFFFF;
            border-radius:0 0 0.213rem 0.213rem;
            width: calc(100% - 0.64rem);
            margin: 0 auto;
            padding: 0.373rem;
            box-sizing: border-box;

            .conter_title{
                width: 100%;
                height: 0.587rem;
                font-size: 0.427rem;
                font-weight: 600;
                color: #383838;
                line-height: 0.587rem;
                margin-bottom: 0.427rem;
            }
            .conter_box_box{
                height: calc(100% - 1.014rem);
                overflow-y: auto;
                .wsj_xob{
                    width: 100%;
                    height: 100%;
                    img{
                        width: calc(100% - 2.72rem);
                        height: auto;
                        margin:0 1.36rem ;
                    }
                    div{
                        width: 100%;
                        height: 0.453rem;
                        font-size: 0.32rem;
                        font-weight: 400;
                        color: #979797;
                        line-height: 0.453rem;
                        text-align: center;
                    }
                }
                .conter_box{
                    display: flex;
                    .conter_box_l{
                        width: 1.333rem;
                        height: 100%;
                        img{
                            width: 1.013rem;
                            height: 1.013rem;
                            border-radius: 0.507rem;
                            margin-right: 0.32rem;
                        }
                    }
                    .conter_box_r{
                        width: calc(100% - 1.333rem);
                        border-bottom: 0.027rem solid #EAEAEA;
                        padding-bottom: 0.32rem;
                        box-sizing: border-box;
                        .conter_box_r_top{
                            width: 100%;
                            height: 1.013rem;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .conter_box_r_top_name{
                                font-size: 0.373rem;
                                font-weight: 400;
                                color: #383838;
                            }
                            .conter_box_r_top_zan{
                                display: flex;
                                align-items: center;
                                img{
                                    width: 0.373rem;
                                    height: 0.347rem;
                                    margin-right: 0.107rem;
                                }
                                div{
                                    font-size: 0.32rem;
                                    font-weight: 400;
                                    color: #979797;
                                }
                            }
                        }
                        .conter_box_lz{
                            width: 100%;
                            background: #FAFAFA;
                            border-radius: 0.107rem;
                            padding: 0.427rem 0.213rem;
                            box-sizing: border-box;
                            font-size: 0.32rem;
                            font-weight: 400;
                            color: #979797;
                            line-height: 0.453rem;
                            margin-bottom: 0.32rem;
                        }
                        .conter_box_r_con{

                            font-size: 0.373rem;
                            font-weight: 400;
                            color: #383838;
                            line-height: 0.587rem;
                        }
                        .conter_box_r_bot{
                            margin-top: 0.107rem;
                            display: flex;
                            justify-content: space-between;
                            font-size: 0.32rem;
                            font-weight: 400;
                            color: #979797;
                            align-items: center;
                        }
                    }
                }
            }

        }

        .bot{
            width: 100%;
            height: 1.573rem;
            background: rgba(241, 242, 247, 1);
            position: fixed;
            left: 0;
            bottom: 0;
            .bot_box{
                width: 100%;
                height: 1.44rem;
                background: #FFFFFF;
                border-radius: 0.213rem 0.213rem 0px 0px;
                position: absolute;
                left: 0;
                bottom: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 0.32rem;
                box-sizing: border-box;
                .bot_l{
                    width: calc(100% - 3.147rem);
                    height: 0.907rem;
                    background: #FFFFFF;
                    border-radius: 0.453rem;
                    border: 0.027rem solid #E6E6E6;
                    line-height:  0.907rem;
                    padding: 0 0.32rem;
                    box-sizing: border-box;
                    font-size: 0.373rem;
                    font-weight: 400;
                    color: #D2D2D2;
                }
                .bot_r{
                    width: 2.72rem;
                    height: 0.853rem;
                    background: #FFABA8;
                    border-radius: 0.427rem;
                    font-size: 0.373rem;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height:  0.853rem;
                    text-align: center;
                }
            }
        }
        .jb_box{
            padding: 1.333rem 0.427rem 0;
            box-sizing: border-box;
            .jb_item{
                width: 100%;
                height: 1.333rem;
                background: rgba(255,255,255,0);
                font-size: 0.373rem;
                font-weight: 400;
                color: #383838;
                line-height: 1.333rem;
                border-bottom: 0.027rem solid rgba(250, 250, 250, 1);

            }
        }
        .tan_put{
            .tan_put_box{
                width: 100%;
                height: 100%;
                padding: 0 0.32rem;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                input{
                    width: calc(100% - 3.147rem);
                    height:0.853rem;
                    background: #FFFFFF;
                    border-radius: 0.453rem;
                    border: 0.027rem solid #E6E6E6;
                    padding: 0 0.32rem;
                    box-sizing: border-box;
                    font-size: 0.373rem;
                    font-weight: 400;
                    color: #383838;
                    line-height: 0.853rem;
                }
                div{
                    width: 2.72rem;
                    height: 0.853rem;
                    background: #FFABA8;
                    border-radius: 0.427rem;
                    line-height: 0.853rem;
                    text-align: center;
                    font-size: 0.373rem;
                    font-weight: 500;
                    color: #FFFFFF;
                }
            }
        }
    }
</style>
