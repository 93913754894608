<template>
    <div class="zhuye">
        <div class="zhuye_tou" :style="isApp ? '    padding-top: 1.3rem;box-sizing: border-box;height: 5.273rem;background-size: 100% 5.273rem;' : ''">
            <div class="app_title_b">
                <van-sticky>
                    <van-nav-bar :title="oCPData.title" left-arrow @click-left="fLeft" />
                </van-sticky>
            </div>
            <div class="user_box">
                <div class="bser_l">
                    <img :src="oCPData.userData.head" alt="" v-if="oCPData.userData.head">
                    <img src="~@/assets/images/new/new_019.png" alt="" v-else>
                    <div class="user_title">
                        <div class="title_top">
                            {{oCPData.userData.roleName}}
                        </div>
                        <div class="title_bot">
                            <div @click="fensi">粉丝 {{oCPData.userData.fansCount}}</div>
                            <div @click="guanzhu">关注 {{oCPData.userData.focusCount}}</div>
                            <div>点赞数 {{oCPData.userData.likeCount}}</div>
                        </div>
                    </div>
                </div>
                <div class="user_r" v-if="!oCPData.userData.focusState && oCPData.userData.roleId != oCPData.roleId" @click="focusOnFun">
                    + 关注
                </div>
                <div class="user_r user_r1" v-if="oCPData.userData.focusState && oCPData.userData.roleId != oCPData.roleId" @click="focusCancelFun">
                    √ 关注
                </div>
                <div class="user_r user_r1" v-if="oCPData.userData.roleId == oCPData.roleId" @click="tobjzhuye">
                    编辑个人信息
                </div>

            </div>
        </div>
        <div class="touzhu">
            <div class="zhutou_box">
                <div class="touzhu_item">
                    <div class="item_top">{{oCPData.userData.weekBetCount}}</div>
                    <div class="item_bot">近7天投注数</div>
                </div>
                <div class="shu"></div>
                <div class="touzhu_item">
                    <div class="item_top">{{oCPData.userData.weekBetHit}}</div>
                    <div class="item_bot">近7天命中</div>
                </div>
                <div class="shu"></div>
                <div class="touzhu_item">
                    <div class="item_top">{{oCPData.userData.weekBetRecordBonus}}%</div>
                    <div class="item_bot">近7天回报率</div>
                </div>
                <div class="shu"></div>
                <div class="touzhu_item">
                    <div class="item_top">￥{{oCPData.userData.threeMonthsBonus}}</div>
                    <div class="item_bot">近半年总奖金</div>
                </div>
            </div>

        </div>
        <div class="jinqi_box">
            <div class="box_title">
                <div :class="oCPData.isDian ? 'title_itemn title_itemn1' : 'title_itemn'" style="left: 1.68rem" @click="dianFun(true)">
                    近7天投注记录
                    <div v-if="oCPData.isDian"></div>
                </div>
                <div :class="!oCPData.isDian ? 'title_itemn title_itemn1' : 'title_itemn'" style="right: 1.68rem" @click="dianFun(false)">
                    近半年中奖记录
                    <div v-if="!oCPData.isDian"></div>
                </div>
            </div>
            <div class="jinqi_conter" id="scroll" @scroll="listenBottomOut">
                <div class="conter_item" v-for="(item,index) in oCPData.yueData" :key="index" @click="toPlanDetail(item.id)">
                    <div class="conter_top">
                        <div class="conter_l">
                            <div class="title">
                                <div class="shu" :style="item.lotteryType == 'JCLQ' ? 'background: #F21B8F' : ''" ></div>
                                <div class="name" v-if="item.lotteryType == 'JCZQ'">竞彩足球</div>
                                <div class="name" v-if="item.lotteryType == 'JCLQ'">竞彩篮球</div>
                                <div class="bq" v-if="item.businessType == 1">发单</div>
<!--                                <div class="bq" v-if="item.businessType == 2">自购</div>-->
                                <div class="bq" v-if="item.businessType == 3" style="background: #F21B8F;color: #ffffff;">跟单</div>
                            </div>
                            <div class="qian">
                                <div class="tou">
                                    <span>投注：</span><span class="qian1">￥{{item.totalFee}}</span>
                                </div>
                                <div class="wan" v-if="oCPData.isDian">
                                    玩法：{{item.mixTypes}}
                                </div>
                            </div>
                        </div>
                        <div class="conter_r" v-if="oCPData.isDian">
                            <img src="~@/assets/images/img_019.png" alt="" v-if="item.weekBetRecordType == 2">
                            <img src="~@/assets/images/img_017.png" alt="" v-if="item.weekBetRecordType == 3">
                            <div v-if="item.weekBetRecordType == 3" class="jine">
                                ¥{{item.prizeFee}}
                            </div>
                            <div class="gemdam" style="margin-top: calc((100% - 0.533rem)/2)" v-if="item.weekBetRecordType == 1">立即跟单</div>
                        </div>
                        <div class="conter_r" v-else>
                            <img src="~@/assets/images/img_017.png" alt="" >
                            <div  class="jine">
                                ¥{{item.prizeFee}}
                            </div>
                        </div>
                    </div>
                    <div class="conter_bot">
                        <div class="sj"  v-if="oCPData.isDian">
                            截止时间  {{fnFormatData(item.stopCopy, "MM-dd hh:mm")}}
                        </div>
                        <div class="sj"  v-if="!oCPData.isDian">
                           {{fnFormatData(item.openPrize, "MM-dd hh:mm")}}
                        </div>
                        <div class="rd"  v-if="oCPData.isDian">
                            <span>
                                热度
                            </span>
                            <span style="font-weight: bold">
                                {{item.heat}}
                            </span>
                        </div>
                    </div>

                </div>

                <div class="wsj_xob" v-if=" oCPData.yueData.length == 0">
                    <img src="~@/assets/images/img_021.png" alt="" >
                    <div>暂无数据</div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
    import {
        fnIsHasHistory
    } from "@/utils/router.js";
    import {
        getHomeStatistic,
        getThreeMonthsBetRecord,
        getWeekBetRecord,
        focusOn,
        focusCancel
    } from '@/api/home';
    import {
        uaFun
    } from "@/utils/public.js";
    import {
        fnFormatData
    } from "@/utils/public.js";
    import {useStore} from "vuex";
    import {useRoute, useRouter} from "vue-router";
    import {reactive,onActivated} from "vue";
    import {Toast} from "vant";
    export default {
        name: "zhuye",
        setup() {
            const isApp = uaFun()
            const store = useStore();
            const route = useRoute();
            const UserInfo = store.state.userInfo.userInfo;
            const router = useRouter();

            const oCPData = reactive({
                userData:{},
                isDian:true,
                size:20,
                yueData:[],
                total:0,
                title:'个人主页',
                roleId:UserInfo.roleId
            })

            const queryMyFun = () =>{
                getHomeStatistic({
                    queryRoleId:route.query.id,
                    roleId: UserInfo.roleId
                }).then(res=>{
                    oCPData.userData = res.result
                })
            }
            const listenBottomOut = () =>{

                //正文总高度
                // console.log(document.getElementById("scroll").scrollTop);
                let scrollHeight = document.getElementById("scroll").scrollHeight;
                //元素可见区域高度
                let offsetHeight = document.getElementById("scroll").offsetHeight;
                //可滚动容器超出当前窗口显示范围的高度
                let scrollTop = document.getElementById("scroll").scrollTop;
                //避免切换时读取到异常高度
                if (scrollTop == 0) {
                    scrollHeight= 10000;
                }
                //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现scrollHeight< (offsetHeight+ scrollTop)的情况，严格来讲，是接近底部。
                //console.log(scrollHeight + " " + offsetHeight+ " " + scrollTop);

                // console.log("加载中~");
                if (scrollTop >= scrollHeight - offsetHeight) {
                    if(oCPData.total != oCPData.redRData.length){
                        //此处添加自定义操作
                        oCPData.size += 20
                        if(oCPData.isDian){
                            queryMyFun()
                        }else {
                            banFun()
                        }

                    }

                }


            };
            // 七天
            const sanyueFun = () =>{
                Toast({
                    type: "loading"
                });
                getWeekBetRecord({
                    current:1,
                    size:oCPData.size,
                    roleId:route.query.id
                }).then(res=>{
                    oCPData.yueData = res.result.records
                    oCPData.total = res.result.total
                })
            }
            // 半年
            const banFun = () =>{
                Toast({
                    type: "loading"
                });
                getThreeMonthsBetRecord({
                    current:1,
                    size:oCPData.size,
                    roleId:route.query.id
                }).then(res=>{
                    oCPData.yueData = res.result.records
                    oCPData.total = res.result.total
                })
            }
            const dianFun = (e) =>{
                oCPData.isDian = e
                if(e){
                    sanyueFun()
                }else {
                    banFun()
                }
            }
            const focusOnFun = () =>{
                focusOn({
                    focusRoleId:route.query.id,
                    roleId:UserInfo.roleId,
                }).then(res=>{
                    console.log(res);
                    if(res.success){
                        Toast('关注成功');
                        queryMyFun()
                    }

                })
            }
            const focusCancelFun = () =>{
                focusCancel({
                    focusRoleId:route.query.id,
                    roleId:UserInfo.roleId,
                }).then(res=>{
                    console.log(res);
                    if(res.success){
                        Toast('取消关注');
                        queryMyFun()
                    }

                })
            }
            // 生命周期
            onActivated(() => {
                queryMyFun()
                sanyueFun()
                if(route.query.id == UserInfo.roleId){
                    oCPData.title = '我的主页'
                }else {
                    oCPData.title = '个人主页'
                }
            });
            /*
            * fLeft van-nav-bar 左侧点击事件
            */
            const fLeft = () => {
                fnIsHasHistory("/plan");
            };
            const toPlanDetail = (Id) =>{
                // 跳转到方案详情
                router.push({
                    path: '/planDetail',
                    query: {
                        id: Id
                    }
                })
            }
            const tobjzhuye = () =>{
                // 跳转到方案详情
                router.push({
                    path: '/mineInfo'
                })
            }
            const fensi = () =>{
                router.push({
                    path: '/fensi',
                    query: {
                        id: oCPData.userData.roleId,
                        userId:oCPData.userData.userId
                    }
                })
            }
            const guanzhu = () =>{
                router.push({
                    path: '/guanzhu',
                    query: {
                        id: oCPData.userData.roleId,
                        userId:oCPData.userData.userId
                    }
                })
            }
            return {
                fLeft,
                fensi,
                guanzhu,
                dianFun,
                fnFormatData,
                listenBottomOut,
                tobjzhuye,
                toPlanDetail,
                banFun,
                focusOnFun,
                focusCancelFun,
                isApp,
                oCPData
            }
        }
    };
</script>

<style scoped lang="scss">
    .zhuye{
        width: 100%;
        height: 100%;
        background: #F1F2F7;
        .zhuye_tou{
            width: 100%;
            height: 3.973rem;
            background-image: url('~@/assets/images/new/new_028.png');
            background-size: 100% 3.973rem;
            background-repeat: no-repeat;
            .user_box{
                width: calc(100% - 0.853rem);
                height: 1.813rem;
                margin-left: 0.427rem;
                display: flex;
                justify-content: space-between;
                margin-top: 0.667rem;
                .bser_l{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    img{
                        width: 1.813rem;
                        height: 1.813rem;
                        margin-right: 0.32rem;
                        border-radius: 50%;
                    }
                    .user_title{
                        height: 100%;
                        padding: 0.32rem 0;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .title_top{
                            font-size: 0.427rem;
                            font-weight: 600;
                            color: #383838;
                        }
                        .title_bot{
                            display: flex;
                            font-size: 0.32rem;
                            font-weight: 400;
                            color: #383838;
                            div{
                                margin-right: 0.32rem;
                            }
                        }
                    }
                }
                .user_r{
                    margin-top: 0.32rem;
                    /*width: 1.733rem;*/
                    padding: 0 0.187rem;
                    box-sizing: border-box;
                    height: 0.587rem;
                    background: #FF7671;
                    border-radius: 0.293rem;
                    font-size: 0.32rem;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height:  0.587rem;
                    text-align: center;
                }
                .user_r1{
                    border: 0.027rem solid #979797;
                    color: #979797;
                    background: rgba(0,0,0,0);
                }
            }
        }
        .touzhu{
           width: 100%;
           height: 3.2rem;
           background: linear-gradient(180deg, #FFFFFF 0%, #F1F2F7 100%);
           .zhutou_box{
               width: 100%;
               height: 1.573rem;
               display: flex;
               align-items: center;
               .touzhu_item{
                   width: calc((100% / 4) );
                   height: 100%;
                   display: flex;
                   flex-direction:column;
                   align-items: center;
                   justify-content: center;
                   .item_top{
                       font-size: 0.4rem;
                       font-weight: bold;
                       color: #E2335D;
                       margin-bottom: 0.133rem;
                   }
                   .item_bot{
                       font-size: 0.32rem;
                       font-weight: 400;
                       color: #383838;
                   }

               }
               .shu{
                   width: 0.027rem;
                   height: 0.56rem;
                   background: #EAEAEA;
               }
           }
       }
        .jinqi_box{
            width: 100%;
            height:calc(100% - 3.973rem - 3.2rem + 1.173rem) ;
            background: #FAFBFF;
            box-shadow: 0px 0.053rem 0.427rem 0px rgba(224,228,246,0.5);
            border-radius: 0.32rem 0.32rem 0px 0px;
            margin-top: -1.173rem;
            .box_title{
                width: 100%;
                height: 1.013rem;
                position: relative;
                .title_itemn{
                    height: 0.64rem;
                    width: 3rem;
                    font-size: 0.373rem;
                    font-weight: 400;
                    color: #383838;
                    line-height:  0.64rem;
                    position: absolute;
                    bottom: 0;
                    text-align: center;
                    div{
                        width: 0.48rem;
                        height: 0.067rem;
                        background: #FF7671;
                        border-radius: 0.04rem;
                        position: absolute;
                        left: calc((100% - 0.48rem)/2);
                        bottom: 0;
                    }
                }
                .title_itemn1{
                    font-size: 0.427rem;
                    font-weight: 600;
                    color: #383838;
                }
            }
            .jinqi_conter{
                width: 100%;
                height: 100%;
                overflow-y: auto;
                padding-bottom: 0.32rem;
                box-sizing: border-box;
                .wsj_xob{
                    width: 100%;
                    height: 100%;
                    background: #FAFBFF;
                    padding-top: 1.333rem;
                    box-sizing: border-box;
                    img{
                        width: calc(100% - 2.72rem);
                        height: auto;
                        margin:0 1.36rem ;
                    }
                    div{
                        width: 100%;
                        height: 0.453rem;
                        font-size: 0.32rem;
                        font-weight: 400;
                        color: #979797;
                        line-height: 0.453rem;
                        text-align: center;
                    }
                }
                .conter_item{

                    width: calc(100% - 0.64rem);
                    height: 3.627rem;
                    margin: 0.32rem 0.32rem 0;
                    background: #FFFFFF;
                    box-shadow: 0px 0.053rem 0.213rem 0px rgba(224,228,246,0.5);
                    border-radius: 0.213rem;
                    padding: 0.213rem 0.4rem 0;
                    box-sizing: border-box;
                    .conter_top{
                        display: flex;
                        justify-content: space-between;
                        padding-bottom: 0.427rem;
                        box-sizing: border-box;
                        border-bottom: 0.027rem solid rgba(234, 234, 234, 1);
                        .conter_l{
                            .title{
                                display: flex;
                                align-items: center;
                                height: 0.48rem;
                                margin-bottom: 0.507rem;
                                .shu{
                                    width: 0.107rem;
                                    height: 0.48rem;
                                    background: #033EC2;
                                    border-radius: 0.053rem;
                                    margin-right: 0.107rem;
                                }
                                .name{
                                    font-size: 0.373rem;
                                    font-weight: 600;
                                    color: #383838;
                                    margin-right: 0.107rem;
                                }
                                .bq{
                                    width: 0.8rem;
                                    height: 0.48rem;
                                    background: #FED530;
                                    border-radius: 0.107rem;
                                    line-height:  0.55rem;
                                    text-align: center;
                                }
                            }
                            .qian{
                                margin-left: 0.107rem;
                                .tou{
                                    font-size: 0.32rem;
                                    font-weight: 400;
                                    color: #383838;
                                    margin-bottom: 0.107rem;
                                    .qian1{
                                        font-size: 0.427rem;
                                        font-weight: bold;
                                        color: #E2335D;
                                    }
                                }
                                .wan{
                                    font-size: 0.32rem;
                                    font-weight: 500;
                                    color: #383838;
                                }
                            }
                        }
                        .conter_r{
                            img{
                                width: 1.573rem;
                                height: 1.52rem;
                            }
                            .jine{
                                font-size: 0.427rem;
                                font-weight: bold;
                                color: #E2335D;
                            }
                            .gemdam{
                                width: 1.92rem;
                                height: 0.533rem;
                                background: #FF7671;
                                border-radius: 0.427rem;
                                font-size: 0.32rem;
                                font-weight: 500;
                                color: #FFFFFF;
                                line-height: 0.633rem;
                                text-align: center;
                            }
                        }
                    }
                    .conter_bot{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 0.853rem;
                        .sj{
                            font-size: 0.32rem;
                            font-weight: 400;
                            color: #979797;
                        }
                        .rd{
                            font-size: 0.267rem;
                            font-weight: 400;
                            color: #E2335D;
                        }
                    }
                }
            }
        }
    }

</style>
