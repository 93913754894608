<template>
    <div class="godBd_box">
        <div class="godBd_tou" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box' : ''">
            <div class="app_title_b">
                <van-sticky>
                    <van-nav-bar title="大神榜单" left-arrow @click-left="fLeft" />
                </van-sticky>
            </div>
            <div class="god_title">
                <div :class="oCPData.isTitle ? 'title_item title_item1' : 'title_item'" @click="titleFun(true)">
                    连红数
                </div>
                <div :class="oCPData.isTitle ? 'title_item' : 'title_item title_item1'" @click="titleFun(false)">
                    回报率
                </div>
            </div>
            <template v-if="oCPData.redRData.length >= 1 && oCPData.redRData[0]" >
                <div class="toux1">
                    <img src="~@/assets/images/new/new_025.png" alt="" class="toux_g">
                    <img :src="oCPData.redRData[0].headPicture" alt="" class="toux_img" v-if="oCPData.redRData[0].headPicture" @click="toZhuye(oCPData.redRData[0].roleId)">
                    <img src="~@/assets/images/new/new_019.png" alt="" class="toux_img" v-else @click="toZhuye(oCPData.redRData[0].roleId)">
                </div>
                <div class="toux1_font">
                    {{oCPData.redRData[0].roleName}}
                </div>
                <div class="toux1_img">
                    <img src="~@/assets/images/new/new_022.png" alt="">
                </div>
                <div class="toux1_lh" v-if="oCPData.isTitle">
                    <p style="font-size: 0.48rem;font-weight: bold;color: #E2335D">{{oCPData.redRData[0].evenRedCount}}</p><p>连红</p>
                </div>
                <div class="toux1_lh toux1_lh1" v-else>
                    <p>回报率</p><p style="font-size: 0.48rem;font-weight: bold;color: #E2335D">{{oCPData.redRData[0].prizeAmount}}%</p>
                </div>

            </template>
            <template v-else>
                <div class="toux1_font">
                    虚位以待
                </div>
                <div class="toux1_img">
                    <img src="~@/assets/images/new/new_022.png" alt="">
                </div>
            </template>

            <template v-if="oCPData.redRData.length >= 2 && oCPData.redRData[1]" >
                <div class="toux1 toux2">
                    <img src="~@/assets/images/new/new_026.png" alt="" class="toux_g">
                    <img :src="oCPData.redRData[1].headPicture" alt="" class="toux_img" v-if="oCPData.redRData[1].headPicture" @click="toZhuye(oCPData.redRData[1].roleId)">
                    <img src="~@/assets/images/new/new_019.png" alt="" class="toux_img" v-else @click="toZhuye(oCPData.redRData[1].roleId)">
                </div>
                <div class="toux2_font toux1_font">
                    {{oCPData.redRData[1].roleName}}
                </div>
                <div class="toux1_img toux2_img">
                    <img src="~@/assets/images/new/new_023.png" alt="">
                </div>
                <div class="toux1_lh toux2_lh " v-if="oCPData.isTitle">
                    <p style="font-size: 0.48rem;font-weight: bold;color: #E2335D">{{oCPData.redRData[1].evenRedCount}}</p><p>连红</p>
                </div>
                <div class="toux1_lh toux2_lh toux1_lh2" v-else>
                    <p>回报率</p><p style="font-size: 0.48rem;font-weight: bold;color: #E2335D">{{oCPData.redRData[1].prizeAmount}}%</p>
                </div>
            </template>
            <template v-else>
                <div class="toux2_font toux1_font">
                    虚位以待
                </div>
                <div class="toux1_img toux2_img">
                    <img src="~@/assets/images/new/new_023.png" alt="">
                </div>
            </template>

            <template v-if="oCPData.redRData.length >= 2 && oCPData.redRData[2]">
                <div class="toux1 toux3">
                    <img src="~@/assets/images/new/new_027.png" alt="" class="toux_g">
                    <img :src="oCPData.redRData[2].headPicture" alt="" class="toux_img" v-if="oCPData.redRData[2].headPicture" @click="toZhuye(oCPData.redRData[2].roleId)">
                    <img src="~@/assets/images/new/new_019.png" alt="" class="toux_img" v-else @click="toZhuye(oCPData.redRData[2].roleId)">
                </div>
                <div class="toux3_font toux1_font">
                    {{oCPData.redRData[2].roleName}}
                </div>
                <div class="toux1_img toux3_img">
                    <img src="~@/assets/images/new/new_024.png" alt="">
                </div>
                <div class="toux1_lh toux3_lh" v-if="oCPData.isTitle">
                    <p style="font-size: 0.48rem;font-weight: bold;color: #E2335D">{{oCPData.redRData[2].evenRedCount}}</p><p>连红</p>
                </div>
                <div class="toux1_lh toux3_lh toux1_lh3" v-else >
                    <p>回报率</p><p style="font-size: 0.48rem;font-weight: bold;color: #E2335D">{{oCPData.redRData[2].prizeAmount}}%</p>
                </div>
            </template>

            <template v-else>
                <div class="toux3_font toux1_font">
                    虚位以待
                </div>
                <div class="toux1_img toux3_img">
                    <img src="~@/assets/images/new/new_024.png" alt="">
                </div>
            </template>


        </div>
        <div class="godBd_cont" id="scroll" @scroll="listenBottomOut"  v-if="oCPData.isTitle">
            <template v-if="oCPData.redRData.length > 3">
                <div class="bang_item" v-for="(item,index) in oCPData.redRData" :key="index" @click="toZhuye(item.roleId)">
                    <div class="bang_item_left" style="width: auto">
                        <div class="xvhao">{{index - (-1)}}</div>
                        <div class="bang_item_left_tou">
                            <div class="tou">
                                <img :src="item.headPicture" alt="" v-if="item.headPicture">
                                <img src="~@/assets/images/new/new_019.png" alt="" v-else>
                            </div>
                        </div>
                        <div class="bang_item_left_name">{{item.roleName}}</div>
                    </div>
                    <div class="bang_item_right1">
                        <div>
                            <img src="~@/assets/images/new/new_005.png" alt="">
                            <div class="font_box">
                                <span style="font-size: 0.32rem;color: #E2335D;margin-right: 0.053rem">{{item.evenRedCount}}</span><span>连胜</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <div class="wsj_xob" v-else>
                <img src="~@/assets/images/img_021.png" alt="" >
                <div>暂无数据</div>
            </div>
        </div>



        <div class="bang_item_box godBd_cont" v-else>
            <template v-if="oCPData.redRData.length > 3">
                <div class="bang_item" v-for="(item,index) in oCPData.redRData" :key="index" @click="toZhuye(item.roleId)">
                    <div class="bang_item_left">
                        <div class="xvhao">{{index - (-1)}}</div>
                        <div class="bang_item_left_tou">
                            <div class="tou">
                                <img :src="item.portraitUrl" alt="" v-if="item.portraitUrl">
                                <img src="~@/assets/images/new/new_019.png" alt="" v-else>
                                <div v-if="item.count > 0">{{item.count}}</div>
                            </div>
                        </div>

                    </div>
                    <div class="bang_item_right" >
                        <div class="bang_item_left_name">{{item.roleName}}</div>
                        <div class="bang_item_right_r_box">
                            <div class="bang_item_right_r">
                                <!--                            <img src="~@/assets/images/new/ic_012.png" alt="" v-if="item.tagName == 'RICHER'">-->
                                <!--                            <img src="~@/assets/images/new/ic_014.png" alt="" v-if="item.tagName == 'HIGH_HIT'">-->
                                <!--                            <img src="~@/assets/images/new/ic_013.png" alt="" v-if="item.tagName == 'CATTLE'">-->
                                <!--                            <img src="~@/assets/images/new/ic_015.png" alt="" v-if="item.tagName == 'POP_LIST'">-->
                                <div class="bang_item_right_title">回报率</div>
                            </div>

                            <div class="bang_item_right_je">{{item.prizeAmount}}%</div>
                        </div>

                    </div>
                </div>

            </template>
            <div class="wsj_xob" v-if="oCPData.redRData.length <= 3">
                <img src="~@/assets/images/img_021.png" alt="" >
                <div>暂无数据</div>
            </div>

        </div>

    </div>
</template>

<script>
    import {
        reactive,
    } from 'vue';
    import {
        fnIsHasHistory
    } from "@/utils/router.js";
    import {
        queryAllEvenRedRank,
        godsRank
    } from '@/api/home';
    import {
        uaFun
    } from "@/utils/public.js";
    import {Toast} from "vant";
    import {useRouter} from "vue-router";
    export default {
        name: "godBd",
        setup() {
            const isApp = uaFun()
            const router = useRouter();
            const oCPData = reactive({
                isTitle:true,
                size:20,
                total:0,
                redRData:[],
            })
            const titleFun = (e) =>{
                oCPData.isTitle = e
                if(e){
                    queryAllEvenRedRankFun()
                }else {
                    godsRankFun()
                }

            }
            const queryAllEvenRedRankFun = () =>{
                Toast({
                    type: "loading"
                });
                queryAllEvenRedRank({
                    current:1,
                    size:oCPData.size
                }).then(res=>{
                    oCPData.total = res.result.total
                    oCPData.redRData =res.result.records
                })
            }
            const godsRankFun = () =>{
                Toast({
                    type: "loading"
                });
                godsRank().then(res=>{
                    oCPData.redRData =res.result
                    oCPData.redRData.forEach(e=>{
                        e.headPicture = e.portraitUrl
                    })
                })
            }
            const listenBottomOut = () =>{

                //正文总高度
                // console.log(document.getElementById("scroll").scrollTop);
                let scrollHeight = document.getElementById("scroll").scrollHeight;
                //元素可见区域高度
                let offsetHeight = document.getElementById("scroll").offsetHeight;
                //可滚动容器超出当前窗口显示范围的高度
                let scrollTop = document.getElementById("scroll").scrollTop;
                //避免切换时读取到异常高度
                if (scrollTop == 0) {
                    scrollHeight= 10000;
                }
                //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现scrollHeight< (offsetHeight+ scrollTop)的情况，严格来讲，是接近底部。
                //console.log(scrollHeight + " " + offsetHeight+ " " + scrollTop);

                // console.log("加载中~");
                if (scrollTop >= scrollHeight - offsetHeight) {
                    if(oCPData.total != oCPData.redRData.length){
                        //此处添加自定义操作
                        oCPData.size += 20
                        queryAllEvenRedRankFun()
                    }

                }


            };
            const toZhuye = (Id) =>{
                // 跳转到方案详情
                router.push({
                    path: '/zhuye',
                    query: {
                        id: Id
                    }
                })
            }
            /*
         * fLeft van-nav-bar 左侧点击事件
         */
            const fLeft = () => {
                fnIsHasHistory("/plan");
            };
            queryAllEvenRedRankFun()
            return {
                fLeft,
                oCPData,
                toZhuye,
                titleFun,
                listenBottomOut,
                godsRankFun,
                isApp
            }
        }
    }
</script>

<style scoped lang="scss">
    .godBd_box{
        width: 100%;
        height: 100%;
        .godBd_tou{
            width: 100%;
            height: 13.333rem;
            background-image: url('~@/assets/images/new/new_021.png');
            background-size: 100% 13.333rem;
            background-repeat: no-repeat;
            .god_title{
                width: 4.8rem;
                height: 0.693rem;
                background: #FCADA3;
                border-radius: 0.213rem;
                font-size: 0.373rem;
                font-weight: 400;
                color: #B02A2A;
                line-height:  0.693rem;
                margin: 0.267rem auto 0;
                display: flex;
                .title_item{
                    width: 50%;
                    height: 100%;
                    text-align: center;
                }
                .title_item1{
                    background: #FFFFFF;
                    color: #E0472B;
                    border-radius: 0.213rem;
                }
            }
            .toux1{
                width: 2.08rem;
                height: 2.08rem;
                background: rgba(255, 255, 255, 0.5);
                position: absolute;
                left: calc((100% - 2.08rem)/2);
                top: 4.1rem;
                border-radius: 1.04rem;
                .toux_img{
                    width: 1.813rem;
                    height: 1.813rem;
                    border-radius: 0.9065rem;
                    position: absolute;
                    left:calc((100% - 1.813rem)/2);
                    top: calc((100% - 1.813rem)/2);
                }
                .toux_g{
                    width: 0.747rem;
                    height: 0.533rem;
                    position: absolute;
                    left: calc((100% - 0.747rem)/2);
                    top: -(0.3rem);
                }
            }
            .toux2{
                left: calc(((100% - 2.08rem)/2) - 2.8rem);
                top: 4.9rem;
            }
            .toux3{
                left: calc(((100% - 2.08rem)/2) + 2.8rem);
                top: 5.6rem;
            }
            .toux1_font{
                width: 2.08rem;
                position: absolute;
                left: calc((100% - 2.08rem)/2);
                top: 6.6rem;
                height: 0.533rem;
                font-size: 0.373rem;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 0.533rem;
                text-align: center;
            }
            .toux2_font{
                left: calc(((100% - 2.08rem)/2) - 2.8rem);
                top: 7.6rem;
            }
            .toux3_font{
                left: calc(((100% - 2.08rem)/2) + 2.8rem);
                top: 8.2rem;
            }
            .toux1_img{
                width: 2.08rem;
                height: 0.747rem;
                position: absolute;
                left: calc((100% - 2.08rem)/2);
                top: 7.5rem;
                img{
                    width: 1.04rem;
                    height: 100%;
                    margin: 0 calc((100% - 1.04rem)/2);
                }
            }
            .toux2_img{
                left: calc(((100% - 2.08rem)/2) - 2.8rem);
                top: 8.5rem;
            }
            .toux3_img{
                left: calc(((100% - 2.08rem)/2) + 2.8rem);
                top: 9rem;
            }
            .toux1_lh{
                width: 2.08rem;
                height: 0.587rem;
                position: absolute;
                left: calc((100% - 2.08rem)/2);
                top: 8.547rem;
                background: rgba(255, 194, 64, 1);
                border-radius: 0.413rem;
                padding: 0 0.2rem;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 0.373rem;
                font-weight: 400;
                color: #383838;
            }
            .toux1_lh1{
                width: 2.747rem;
                left: calc((100% - 2.747rem)/2);
            }
            .toux2_lh{
                left: calc(((100% - 2.08rem)/2) - 2.8rem);
                top: 9.547rem;
            }
            .toux1_lh2{
                width: 2.747rem;
                left: calc(((100% - 2.747rem)/2) - 2.9rem);
            }
            .toux3_lh{
                left: calc(((100% - 2.08rem)/2) + 2.8rem);
                top: 10.047rem;
            }
            .toux1_lh3{
                width: 2.747rem;
                left: calc(((100% - 2.747rem)/2) + 2.9rem);
            }
        }
        .godBd_cont{
            width: 100%;
            height: 10.587rem;
            background: #FAFBFF;
            box-shadow: 0px 0.053rem 0.427rem 0px rgba(224,228,246,0.5);
            border-radius: 0.32rem;
            margin-top: -2rem;
            padding: 0.587rem 0.32rem;
            box-sizing: border-box;
            .bang_item{
                width: 100%;
                height: 1.52rem;
                background: #FFFFFF;
                box-shadow: 0px 0.053rem 0.213rem 0px rgba(224,228,246,0.5);
                border-radius: 0.213rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0.4rem auto 0.32rem;
                .bang_item_left{
                    display: flex;
                    align-items: center;
                    width: 1.013rem;
                    .xvhao{
                        width: 0.8rem;
                        height: 0.853rem;
                        font-size: 0.32rem;
                        font-weight: 400;
                        color: #383838;
                        line-height: 0.853rem;
                        text-align: center;
                        margin-left: 0.32rem;
                    }
                    .bang_item_left_tou{
                        width: 1.013rem;
                        height: 1.013rem;
                        margin: 0 0.293rem 0 0;
                        .tou{
                            width: 1.013rem;
                            height: 1.013rem;
                            position: relative;
                            img{
                                width: 1.013rem;
                                height: 1.013rem;
                                border-radius: 0.507rem;
                            }
                            div{
                                padding: 0 0.107rem;
                                box-sizing: border-box;
                                height: 0.427rem;
                                background: #FF7671;
                                border-radius: 0.213rem;
                                line-height: 0.427rem;
                                text-align: center;
                                position: absolute;
                                top: 0;
                                right: 0;
                                font-size: 0.32rem;
                                font-weight: 500;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
                .bang_item_right1{
                    /*width: 1.067rem;*/
                    height: 100%;
                    padding-top: 0.32rem;
                    box-sizing: border-box;
                    margin-right: 0.267rem;
                    div{
                        height: 0.667rem;
                        position: relative;
                        img{
                            width: 1.267rem;
                            height: 0.667rem;
                        }
                        .font_box{
                            width:100%;
                            height: 0.667rem;
                            position: absolute;
                            top: 0;
                            right: 0;
                            line-height: 0.973rem;
                            text-align: center;
                            span{
                                font-size: 0.213rem;
                                font-weight: 500;
                                color: #383838;
                            }
                        }
                    }

                    /*height: 0.667rem;*/
                }
                .bang_item_right{
                    width: calc(100% - 2.013rem);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-right:0.267rem ;
                    box-sizing: border-box;
                    .bang_item_right_r_box{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-end;
                        .bang_item_right_r{
                            display: flex;
                            align-items: flex-end;
                            img{
                                width: 0.8rem;
                                height: 0.8rem;
                                margin-right: 0.213rem;
                            }
                            .bang_item_right_title{
                                font-size: 0.32rem;
                                font-weight: 600;
                                color: #383838;
                                line-height: 0.453rem;
                            }
                        }
                        .bang_item_right_je{
                            font-size: 0.373rem;
                            font-weight: bold;
                            color: #E2335D;
                            line-height: 0.427rem;
                        }
                    }



                }
            }
            .wsj_xob{
                width: 100%;
                height: 100%;

                img{
                    width: calc(100% - 2.72rem);
                    height: auto;
                    margin:0 1.36rem ;
                }
                div{
                    width: 100%;
                    height: 0.453rem;
                    font-size: 0.32rem;
                    font-weight: 400;
                    color: #979797;
                    line-height: 0.453rem;
                    text-align: center;
                }
            }
        }
        .bang_item_box,.item_chudi{
            overflow-y: auto;
            height: 8.853rem;
            .wsj_xob{
                width: 100%;
                height: 100%;
                img{
                    width: calc(100% - 2.72rem);
                    height: auto;
                    margin: 2rem 1.36rem 0;
                }
                div{
                    width: 100%;
                    height: 0.453rem;
                    font-size: 0.32rem;
                    font-weight: 400;
                    color: #979797;
                    line-height: 0.453rem;
                    text-align: center;
                }
            }
            .bang_item{
                width:100%;
                height: 1.52rem;
                background: #FFFFFF;
                box-shadow: 0px 0.053rem 0.213rem 0px rgba(224,228,246,0.5);
                border-radius: 0.213rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0.4rem auto 0.32rem;
                .bang_item_left{
                    display: flex;
                    align-items: center;
                    width: 1.013rem;
                    .bang_item_left_tou{
                        width: 1.013rem;
                        height: 1.013rem;
                        margin: 0 0.293rem 0 0.4rem;
                        .tou{
                            width: 1.013rem;
                            height: 1.013rem;
                            position: relative;
                            img{
                                width: 1.013rem;
                                height: 1.013rem;
                                border-radius: 0.507rem;
                            }
                            div{
                                padding: 0 0.107rem;
                                box-sizing: border-box;
                                height: 0.427rem;
                                background: #FF7671;
                                border-radius: 0.213rem;
                                line-height: 0.427rem;
                                text-align: center;
                                position: absolute;
                                top: 0;
                                right: 0;
                                font-size: 0.32rem;
                                font-weight: 500;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
                .bang_item_right1{
                    /*width: 1.067rem;*/
                    height: 100%;
                    padding-top: 0.32rem;
                    box-sizing: border-box;
                    margin-right: 0.267rem;
                    div{
                        height: 0.667rem;
                        position: relative;
                        img{
                            width: 1.067rem;
                            height: 0.667rem;
                        }
                        .font_box{
                            width: 100%;
                            height: 0.667rem;
                            position: absolute;
                            top: 0;
                            right: 0;
                            line-height: 0.973rem;
                            text-align: center;
                            span{
                                font-size: 0.213rem;
                                font-weight: 500;
                                color: #383838;
                            }
                        }
                    }

                    /*height: 0.667rem;*/
                }
                .bang_item_right{
                    width: calc(100% - 2.013rem);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-right:0.267rem ;
                    box-sizing: border-box;
                    .bang_item_right_r_box{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-end;
                        .bang_item_right_r{
                            display: flex;
                            align-items: flex-end;
                            img{
                                width: 0.8rem;
                                height: 0.8rem;
                                margin-right: 0.213rem;
                            }
                            .bang_item_right_title{
                                font-size: 0.32rem;
                                font-weight: 600;
                                color: #383838;
                                line-height: 0.453rem;
                            }
                        }
                        .bang_item_right_je{
                            font-size: 0.373rem;
                            font-weight: bold;
                            color: #E2335D;
                            line-height: 0.427rem;
                        }
                    }



                }
            }
        }
    }


</style>
