
<template>
    <div class="guanzhu" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box' : ''">
        <div class="app_title_b">
            <van-sticky>
                <van-nav-bar title="我的关注" left-arrow @click-left="fLeft" />
            </van-sticky>
        </div>
        <div id="scroll" @scroll="listenBottomOut" v-if="oCPData.userData.length != 0">
            <div class="search_jg" v-for="(item,index) in oCPData.userData"   :key="index">
                <div class="search_jg_left" @click="toZhuye(item.focusRoleId)">
                    <img :src="item.head" alt="" v-if="item.head" class="tou5">
                    <img src="~@/assets/images/new/new_019.png" alt="" v-else class="tou5">
                    <div class="name4">{{item.focusRoleName}}</div>
                    <div class="bang_item_right1" style="margin-left: 0.267rem" v-if="item.evenRedCount >= 1">
                        <div>
                            <img src="~@/assets/images/new/new_005.png" alt="">
                            <div class="font_box">
                                <span style="font-size: 0.32rem;color: #E2335D;margin-right: 0.053rem">{{item.evenRedCount}}</span><span>连红</span>
                            </div>
                        </div>



                    </div>
                </div>

                <div class="search_jg_r">
                    <div class="user_r" v-if="!item.focusState" @click="focusOnFun(item)">
                        + 关注
                    </div>
                    <div class="user_r user_r1" v-if="item.focusState" @click="focusCancelFun(item)">
                        √ 关注
                    </div>
                </div>

            </div>
        </div>

        <div class="wsj_xob" v-if="oCPData.userData.length == 0">
            <img src="~@/assets/images/img_021.png" alt="" >
            <div>暂无数据</div>
        </div>
    </div>
</template>

<script>
    import {
        fnIsHasHistory
    } from "@/utils/router.js";
    import {
        focusOn,
        focusCancel,
        queryMemberFocusInfosForPage
    } from '@/api/home';
    import {
        uaFun
    } from "@/utils/public.js";

    import {useRoute, useRouter} from "vue-router";
    import {useStore} from "vuex";
    import {onActivated, reactive} from "vue";
    import {Toast} from "vant";
    export default {
        name: "guanzhu",
        setup(){
            const isApp = uaFun()
            const router = useRouter(); //初始化路由
            const store = useStore();
            const route = useRoute();
            const UserInfo = store.state.userInfo.userInfo;
            // fLeft van-nav-bar 左侧点击事件
            const fLeft = () => {
                fnIsHasHistory("/zhuye");
            };
            const oCPData = reactive({
                userData:[],
                size:20,
                total:0
            })
            const fensiFun = () =>{
                Toast({
                    type: "loading"
                });
                queryMemberFocusInfosForPage({
                    current:1,
                    size:oCPData.size,
                    currentUserId:UserInfo.userId,
                    currentRoleId:UserInfo.roleId,
                    roleId:route.query.id,
                    userId:route.query.userId
                }).then(res=>{
                    oCPData.userData = res.result.records;
                    oCPData.total = res.result.total;
                })
            }

            const toZhuye = (Id) =>{
                // 跳转到方案详情
                router.push({
                    path: '/zhuye',
                    query: {
                        id: Id
                    }
                })
            }
            const listenBottomOut = () =>{
                console.log(123123);
                //正文总高度
                // console.log(document.getElementById("scroll").scrollTop);
                let scrollHeight = document.getElementById("scroll").scrollHeight;
                //元素可见区域高度
                let offsetHeight = document.getElementById("scroll").offsetHeight;
                //可滚动容器超出当前窗口显示范围的高度
                let scrollTop = document.getElementById("scroll").scrollTop;
                //避免切换时读取到异常高度
                if (scrollTop == 0) {
                    scrollHeight= 10000;
                }
                //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现scrollHeight< (offsetHeight+ scrollTop)的情况，严格来讲，是接近底部。
                //console.log(scrollHeight + " " + offsetHeight+ " " + scrollTop);

                // console.log("加载中~");
                if (scrollTop >= scrollHeight - offsetHeight) {
                    if(oCPData.total != oCPData.redRData.length){
                        //此处添加自定义操作
                        oCPData.size += 20
                        fensiFun()

                    }

                }


            };
            // 生命周期
            onActivated(() => {
                fensiFun()
            });
            const focusOnFun = (e) =>{
                focusOn({
                    focusRoleId:e.focusRoleId,
                    roleId:UserInfo.roleId,
                }).then(res=>{
                    console.log(res);
                    if(res.success){
                        Toast('关注成功');
                        fensiFun()
                    }

                })
            }
            const focusCancelFun = (e) =>{
                focusCancel({
                    focusRoleId:e.focusRoleId,
                    roleId:UserInfo.roleId,
                }).then(res=>{
                    console.log(res);
                    if(res.success){
                        Toast('取消关注');
                        fensiFun()
                    }

                })
            }
            return{
                fLeft,
                focusOnFun,
                toZhuye,
                focusCancelFun,
                listenBottomOut,
                isApp,
                oCPData

            }
        }
    }
</script>

<style scoped lang="scss">
    .guanzhu{
        width: 100%;
        height: 100%;
        .search_top{
            width: 100%;
            height: 0.907rem;
            display: flex;
            justify-content: space-between;
            padding: 0 0.4rem ;
            box-sizing: border-box;

            align-items: center;
            .zuo{
                width: 0.213rem;
                height: 0.373rem;
            }
            .search_box{
                width: calc(100% - 2.667rem);
                height: 100%;
                border-radius: 0.453rem;
                border: 0.027rem solid #FF7671;
                display: flex;
                align-items: center;
                padding: 0 0.267rem;
                box-sizing: border-box;
                img{
                    width: 0.4rem;
                    height: 0.373rem;
                    margin-right: 0.24rem;
                }
                input::-webkit-input-placeholder {
                    color: #D2D2D2;
                }

                input::-moz-placeholder {
                    /* Mozilla Firefox 19+ */
                    color: #D2D2D2;
                }

                input:-moz-placeholder {
                    /* Mozilla Firefox 4 to 18 */
                    color: #D2D2D2;
                }

                input:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #D2D2D2;
                }
                #search{
                    height: 0.587rem;
                    font-size: 0.427rem;
                    font-weight: 400;
                    color: #FF7671;
                    line-height: 0.587rem;
                    caret-color:#FF7671;
                }
            }
            .search_title{
                font-size: 0.427rem;
                font-weight: 400;
                color: #E2335D;
            }
        }
        .wsj_xob{
            width: 100%;
            /*height: 100%;*/
            padding-top: 2.667rem;
            box-sizing: border-box;

            img{
                width: calc(100% - 2.72rem);
                height: auto;
                margin:0 1.36rem ;
            }
            div{
                width: 100%;
                height: 0.453rem;
                font-size: 0.32rem;
                font-weight: 400;
                color: #979797;
                line-height: 0.453rem;
                text-align: center;
            }
        }
        #scroll{
            width: 100%;
            height: calc(100% - 0.907rem);
            overflow-y: auto;
            .search_jg{

                width: calc(100% - 1.44rem);
                height: 1.493rem;
                margin: 0.453rem auto 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 0.013rem solid #F5F5F5;
                .search_jg_left{
                    width: calc(100% - 1.44rem);
                    height: 1.493rem;
                    margin: 0.453rem auto 0;
                    display: flex;
                    align-items: center;
                    .tou5{
                        width: 1.013rem;
                        height: 1.013rem;
                        margin-right: 0.32rem;
                        border-radius: 0.507rem;
                    }
                    .name4{
                        font-size: 0.373rem;
                        font-weight: 400;
                        color: #383838;
                    }
                    .bang_item_right1{
                        /*width: 1.067rem;*/
                        height: 100%;
                        padding-top: 0.32rem;
                        box-sizing: border-box;
                        margin-right: 0.267rem;
                        div{
                            height: 0.667rem;
                            position: relative;
                            img{
                                width: 1.067rem;
                                height: 0.667rem;
                            }
                            .font_box{
                                width: 100%;
                                height: 0.667rem;
                                position: absolute;
                                top: 0;
                                right: 0;
                                line-height: 0.973rem;
                                text-align: center;
                                span{
                                    font-size: 0.213rem;
                                    font-weight: 500;
                                    color: #383838;
                                }
                            }
                        }

                        /*height: 0.667rem;*/
                    }
                }
                .search_jg_r{
                    .user_r{
                        margin-top: 0.32rem;
                        width: 1.733rem;
                        height: 0.587rem;
                        background: #FF7671;
                        border-radius: 0.293rem;
                        font-size: 0.32rem;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height:  0.587rem;
                        text-align: center;
                    }
                    .user_r1{
                        border: 0.027rem solid #979797;
                        color: #979797;
                        background: rgba(0,0,0,0);
                    }
                }

            }
        }
    }


</style>
