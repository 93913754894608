<template>
    <div class="match" :style="isApp ? 'padding-top: 2.547rem' : ''">
        <div class="gendan" :style="isApp ? 'top: 1.3rem' : ''">
            <div></div>
            <div class="bang_top">
                <div class="bang_title_box" style="margin-right: 0.427rem" @click="bangFun">
                    <div class="bang_title" :style="oCPData.bang.styleBang1">
                        竞彩足球
                        <img v-if="!oCPData.bang.isBang" src="~@/assets/images/new/new_004.png" alt="">
                    </div>
                </div>
                <div class="bang_title_box" @click="bangFun1">
                    <div class="bang_title"  :style="oCPData.bang.styleBang2">
                        竞彩篮球
                        <img v-if="oCPData.bang.isBang" src="~@/assets/images/new/new_004.png" alt="">
                    </div>
                </div>
            </div>
            <img src="~@/assets/images/new/jc_019.png" alt="" class="gz" @click="fnGoGeneralTxt('playWayZQ')">
        </div>

        <div class="footBall app_page app_flex" style="height: calc(100% - 3rem);padding-top: 0;" v-if="!oCPData.bang.isBang">
        <!--            <div class="app_title_c">-->
        <!--                <van-sticky>-->
        <!--                    <van-nav-bar left-arrow right-text="玩法规则" @click-right="fRight" safe-area-inset-top>-->
        <!--                        <template #left>-->
        <!--                            <img class="app_title_c_left_icon" src="~@/assets/icon/icon-008.png" @click="fLeft">-->
        <!--                            <img class="app_title_c_left_img" src="~@/assets/images/img_012.png">-->
        <!--                            <span class="app_title_c_left_title">混合过关</span>-->
        <!--                        </template>-->
        <!--                        <template #right>-->
        <!--                            <span class="app_title_c_right_title">玩法规则</span>-->
        <!--                        </template>-->
        <!--                    </van-nav-bar>-->
        <!--                </van-sticky>-->
        <!--            </div>-->
            <div v-if="aMatch.length" class="footBall_body app_scroll">
                <van-collapse v-model="nActiveNames" :border="false">
                    <van-collapse-item v-for="(item,index) in aMatch" class="footBall_day"
                                               :class="{'footBall_dayC': nActiveNames.indexOf(item.issueNo) === -1 }" :name="item.issueNo"
                                               :key="index">
                        <template #title>
                            <div class="footBall_day_title">
                                <div class="footBall_day_title_a">
                                    <span class="footBall_day_title_a_date">
                                        {{item.issueNo}}
                                    </span>
                                    <span class="footBall_day_title_a_week">{{fIsToday(item.issueNo) ? "今日" : item.weekStr}}
                                    </span>
                                </div>
                                        <span v-show="nActiveNames.indexOf(item.issueNo) !== -1" class="footBall_day_title_b">共<em>{{item.gameNum}}</em>场比赛</span>
                                    </div>
                                    <div v-show="nActiveNames.indexOf(item.issueNo) === -1" class="footBall_day_close">
                                        当日共 <em>{{item.gameNum}}</em> 场比赛
                                    </div>
                                </template>
                                <template #right-icon>
                                    <div class="footBall_day_rIcon" v-show="nActiveNames.indexOf(item.issueNo) !== -1">
                                        <span class="footBall_day_rIcon_text">收起</span>
                                        <img class="footBall_day_rIcon_icon" src="~@/assets/icon/icon-009.png">
                                    </div>
                                    <div class="footBall_day_rIcon" v-show="nActiveNames.indexOf(item.issueNo) === -1">
                                        <span class="footBall_day_rIcon_text">展开</span>
                                        <img class="footBall_day_rIcon_icon" src="~@/assets/icon/icon-010.png">
                                    </div>
                                </template>
                                <div class="footBall_options" v-for="(itemA, indexA) in item.responseList" :key="indexA">
                                    <div class="footBall_options_title">
                                        <div class="footBall_options_title_a">
                                            <span>{{itemA.hostShortName}}</span>
                                            <img src="~@/assets/icon/icon-011.png">
                                            <span>{{itemA.guestShortName}}</span>
                                        </div>
                                        <div class="footBall_options_title_b">{{itemA.deadlineStr}}截止</div>
                                        <div class="footBall_options_title_c">
                                            <img class="footBall_options_title_c_lIcon" src="~@/assets/icon/icon-013.png">
                                            <div>
                                                <em>{{itemA.gameNo}}</em>
                                                <span>{{itemA.gameName}}</span>
                                            </div>
                                            <img class="footBall_options_title_c_rIcon" src="~@/assets/icon/icon-014.png">
                                        </div>
                                    </div>

                                    <div class="footBall_options_content" style="padding-bottom: 0">
                                        <div class="footBall_options_content_three">
                                            <div class="footBall_options_content_three_typeSPF">0</div>
                                            <div class="footBall_options_content_three_typeOptions" v-if="itemA.isSupportSpfGg">
                                                <template v-for="(itemB,indexB) in oCPData.footballSpfResponse" :key="indexB">
                                                    <div :class="{'footBall_check': itemA.footballSpfResponse && itemA.footballSpfResponse[itemB.select],'footBall_single' : indexB === 0 && itemA.isSupportSpfDg}"
                                                         @click="fSelectOptions(index,indexA,itemB,'footballSpfResponse')">
                                                        <p>{{itemB.name}}</p>
                                                        <p>
                                                            {{itemA.footballSpfResponse && itemA.footballSpfResponse[itemB.key]}}
                                                        </p>
                                                    </div>
                                                </template>
                                            </div>
                                            <div v-else class="footBall_options_none">
                                                当前玩法不支持投注
                                            </div>
                                        </div>
                                        <div class="footBall_options_content_three">
                                            <div class="footBall_options_content_three_typeRQSPF">
                                                {{itemA.rqspfPlate>0? '+' + itemA.rqspfPlate : itemA.rqspfPlate}}
                                            </div>
                                            <div class="footBall_options_content_three_typeOptions" v-if="itemA.isSupportRqspfGg">
                                                <template v-for="(itemB,indexB) in oCPData.footballRqspfResponse" :key="indexB">
                                                    <div :class="{'footBall_check': itemA.footballRqspfResponse && itemA.footballRqspfResponse[itemB.select], 'footBall_single' : indexB === 0 && itemA.isSupportRqspfDg}"
                                                         @click="fSelectOptions(index,indexA,itemB,'footballRqspfResponse')">
                                                        <p>{{itemB.name}}</p>
                                                        <p>{{itemA.footballRqspfResponse && itemA.footballRqspfResponse[itemB.key]}}
                                                        </p>
                                                    </div>
                                                </template>
                                            </div>
                                            <div v-else class="footBall_options_none">
                                                当前玩法不支持投注
                                            </div>
                                        </div>
                                        <div class="footBall_options_other"
                                             :class="{'footBall_options_other_select' : itemA.selectMore > 0}"
                                             @click="fOpenMore(index,indexA,itemA)">更多选项</div>
                                        <div class="dianz">
                                            <div class="dianz_item" @click="toPl(itemA.id)">
                                                <img src="~@/assets/images/new/jc_021.png" alt="">
                                                <div>{{itemA.commentNum }}</div>
                                            </div>
                                            <div class="dianz_item">
                                                <img src="~@/assets/images/new/jc_022.png" alt="" v-if="itemA.agreeSign" @click="dianzFun(itemA,false)">
                                                <img src="~@/assets/images/new/ic_02.png" alt="" v-else  @click="dianzFun(itemA,true)">
                                                <div>{{itemA.agreeNum}}</div>
                                            </div>
                                            <div class="dianz_item">
                                                <img src="~@/assets/images/new/jc_023.png" alt="">
                                                <div>0</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </van-collapse-item>
                        </van-collapse>
                    </div>
                    <div v-else class="footBall_none app_scroll">
                        <van-empty class="footBall_none_empty" :image="require('@/assets/images/img_021.png')" description="暂无赛事" />
                    </div>

                    <div class="footBall_bottom">
                        <div class="footBall_bottom_left">
                            <img src="~@/assets/icon/icon-015.png" @click="fCleanAll">
                            <span v-show="aMatchSelect.length>0">已选 <em
                                    class="footBall_bottom_left_em2">{{aMatchSelect.length}}</em> 场</span>
                            <span v-show="aMatchSelect.length===0">非 <em class="footBall_bottom_left_em1">单关</em> 至少选择 <em
                                    class="footBall_bottom_left_em2">2</em>场比赛</span>
                        </div>
                        <span class="footBall_bottom_right " :class="{'footBall_bottom_right--disabled': bIsDisabled}"
                              @click="fBet">立即投注</span>
                    </div>

                    <h-dialog-b :title="dialogB.title" dialogKey="dialogB" :show="dialogB.showDialog"
                                :showCancelButton="dialogB.showCancelButton" :confirmButtonText="dialogB.confirmButtonText"
                                @fBeforeClose="fBeforeClose">
                        <div class="footBall_allOption app_scroll">
        <!--                    <van-button class="mb10" type="primary" @click="fSelectAll">全选</van-button>-->
                            <div class="footBall_allOption_way">
                                <div class="footBall_allOption_way_title">
                                    胜平负 / 让球
                                </div>
                                <div class="footBall_allOption_way_content">
                                    <div class="footBall_allOption_way_content_three">
                                        <div class="footBall_allOption_way_content_three_typeSPF">0</div>
                                        <div v-if="dialogB.match.isSupportSpfGg" class="footBall_allOption_way_content_three_typeOptions footBall_allOption_way_content_three_typeSPFOptions">
                                            <template v-for="(itemB,indexB) in oCPData.footballSpfResponse" :key="indexB">
                                                <div :class="{'footBall_check': dialogB.match.footballSpfResponse && dialogB.match.footballSpfResponse[itemB.select],'footBall_single' : indexB === 0 && dialogB.match.isSupportSpfDg}"
                                                     @click="fSelectMoreOptions(itemB, 'footballSpfResponse')">
                                                    <p>{{itemB.name}}</p>
                                                    <p>
                                                        {{dialogB.match.footballSpfResponse && dialogB.match.footballSpfResponse[itemB.key]}}
                                                    </p>
                                                </div>
                                            </template>
                                        </div>
                                        <div v-else class="footBall_allOption_none footBall_allOption_noneAB">
                                            当前玩法不支持投注
                                        </div>
                                    </div>
                                    <div class="footBall_allOption_way_content_three">
                                        <div class="footBall_allOption_way_content_three_typeRQSPF">{{dialogB.match.rqspfPlate}}
                                        </div>
                                        <div v-if="dialogB.match.isSupportRqspfGg" class="footBall_allOption_way_content_three_typeOptions footBall_allOption_way_content_three_typeRQSPFOptions">
                                            <template v-for="(itemB,indexB) in oCPData.footballRqspfResponse" :key="indexB">
                                                <div :class="{'footBall_check': dialogB.match.footballRqspfResponse && dialogB.match.footballRqspfResponse[itemB.select],'footBall_single' : indexB === 0 && dialogB.match.isSupportRqspfDg}"
                                                     @click="fSelectMoreOptions(itemB, 'footballRqspfResponse')">
                                                    <p>{{itemB.name}}</p>
                                                    <p>
                                                        {{dialogB.match.footballRqspfResponse && dialogB.match.footballRqspfResponse[itemB.key]}}
                                                    </p>
                                                </div>
                                            </template>
                                        </div>
                                        <div v-else class="footBall_allOption_none footBall_allOption_noneCD">
                                            当前玩法不支持投注
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="footBall_allOption_way">
                                <div class="footBall_allOption_way_title">
                                    <img v-if="dialogB.match.isSupportBfDg" src="~@/assets/icon/icon-012.png"> 比分
                                </div>

                                <div class="footBall_allOption_way_content">
                                    <div class="footBall_allOption_way_content_five">
                                        <div class="footBall_allOption_way_content_typeOther">主胜</div>
                                        <div v-if="dialogB.match.isSupportBf"
                                             class="footBall_allOption_way_content_flex5 footBall_allOption_way_content_flex5_typeSF">
                                            <template v-for="(itemB,indexB) in oCPData.bfZSMatchResponse" :key="indexB">
                                                <div :class="{'footBall_check': dialogB.match.footballBfResponse && dialogB.match.footballBfResponse[itemB.select] }"
                                                     @click="fSelectMoreOptions(itemB, 'footballBfResponse')">
                                                    <p>{{itemB.name}}</p>
                                                    <p>
                                                        {{dialogB.match.footballBfResponse && dialogB.match.footballBfResponse[itemB.key]}}
                                                    </p>
                                                </div>
                                            </template>
                                        </div>
                                        <div v-else class="footBall_allOption_none app_h60">
                                            当前玩法不支持投注
                                        </div>
                                    </div>
                                    <div class="footBall_allOption_way_content_five">
                                        <div class="footBall_allOption_way_content_typeOther">平</div>
                                        <div v-if="dialogB.match.isSupportBf"
                                             class="footBall_allOption_way_content_flex5 footBall_allOption_way_content_flex5_typeP">
                                            <template v-for="(itemB,indexB) in oCPData.bfPMatchResponse" :key="indexB">
                                                <div :class="{'footBall_check': dialogB.match.footballBfResponse && dialogB.match.footballBfResponse[itemB.select] }"
                                                     @click="fSelectMoreOptions(itemB, 'footballBfResponse')">
                                                    <p>{{itemB.name}}</p>
                                                    <p>
                                                        {{dialogB.match.footballBfResponse && dialogB.match.footballBfResponse[itemB.key]}}
                                                    </p>
                                                </div>
                                            </template>
                                        </div>
                                        <div v-else class="footBall_allOption_none app_h60">
                                            当前玩法不支持投注
                                        </div>
                                    </div>

                                    <div class="footBall_allOption_way_content_five">
                                        <div class="footBall_allOption_way_content_typeOther">主负</div>
                                        <div v-if="dialogB.match.isSupportBf"
                                             class="footBall_allOption_way_content_flex5 footBall_allOption_way_content_flex5_typeSF">
                                            <template v-for="(itemB,indexB) in oCPData.bfZFMatchResponse" :key="indexB">
                                                <div :class="{'footBall_check': dialogB.match.footballBfResponse && dialogB.match.footballBfResponse[itemB.select] }"
                                                     @click="fSelectMoreOptions(itemB, 'footballBfResponse')">
                                                    <p>{{itemB.name}}</p>
                                                    <p>
                                                        {{dialogB.match.footballBfResponse && dialogB.match.footballBfResponse[itemB.key]}}
                                                    </p>
                                                </div>
                                            </template>
                                        </div>
                                        <div v-else class="footBall_allOption_none app_h60">
                                            当前玩法不支持投注
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="footBall_allOption_way">
                                <div class="footBall_allOption_way_title">
                                    <img v-if="dialogB.match.isSupportJqsDg" src="~@/assets/icon/icon-012.png"> 进球数
                                </div>

                                <div class="footBall_allOption_way_content">
                                    <div class="footBall_allOption_way_content_five">
                                        <div class="footBall_allOption_way_content_typeOther">进球数</div>

                                        <div v-if="dialogB.match.isSupportJqs" class="footBall_allOption_way_content_flex4">
                                            <template v-for="(itemB,indexB) in oCPData.footballJqsResponse" :key="indexB">
                                                <div :class="{'footBall_check': dialogB.match.footballJqsResponse && dialogB.match.footballJqsResponse[itemB.select] }"
                                                     @click="fSelectMoreOptions(itemB, 'footballJqsResponse')">

                                                    <p>{{itemB.name}}</p>
                                                    <p>
                                                        {{dialogB.match.footballJqsResponse && dialogB.match.footballJqsResponse[itemB.key]}}
                                                    </p>
                                                </div>
                                            </template>
                                        </div>

                                        <div v-else class="footBall_allOption_none app_h60">
                                            当前玩法不支持投注
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="footBall_allOption_way">
                                <div class="footBall_allOption_way_title">
                                    <img v-if="dialogB.match.isSupportBqcspfDg" src="~@/assets/icon/icon-012.png"> 半全场
                                </div>

                                <div class="footBall_allOption_way_content">
                                    <div class="footBall_allOption_way_content_five">
                                        <div class="footBall_allOption_way_content_typeOther">半全场</div>
                                        <div v-if="dialogB.match.isSupportBqcspf" class="footBall_allOption_way_content_flex3">
                                            <template v-for="(itemB,indexB) in oCPData.footballBqcspfResponse" :key="indexB">
                                                <div :class="{'footBall_check': dialogB.match.footballBqcspfResponse && dialogB.match.footballBqcspfResponse[itemB.select] }"
                                                     @click="fSelectMoreOptions(itemB, 'footballBqcspfResponse')">
                                                    <p>{{itemB.name}}</p>
                                                    <p>
                                                        {{dialogB.match.footballBqcspfResponse && dialogB.match.footballBqcspfResponse[itemB.key]}}
                                                    </p>
                                                </div>
                                            </template>
                                        </div>
                                        <div v-else class="footBall_allOption_none app_h60">
                                            当前玩法不支持投注
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </h-dialog-b>
<!--                    <h-dialog-c title="提示" dialogKey="dialogC" :show="dialogC.showDialog"-->
<!--                                :showCancelButton="dialogC.showCancelButton" :confirmButtonText="dialogC.confirmButtonText"-->
<!--                                @fBeforeClose="fBeforeClose">-->
<!--                        <div class="footBall_tips">-->
<!--                            当前有未提交的购彩信息，如果执行返回您将自动放弃购彩信息！-->
<!--                        </div>-->
<!--                    </h-dialog-c>-->
                </div>


        <Basketball v-if="oCPData.bang.isBang"></Basketball>

<!--        <div v-if="oCPData.bang.isBang" class="basketball app_page app_flex" style="height: calc(100% - 3rem);padding-top: 0;">-->
<!--            <div v-if="aMatch.length" class="basketball_body   app_scroll" style="padding: 0.2933rem 0.32rem 0;box-sizing: border-box">-->
<!--                <van-collapse v-model="nActiveNames" :border="false">-->
<!--                    <van-collapse-item v-for="(item,index) in aMatch" class="basketball_day"-->
<!--                                       :class="{'basketball_dayC': nActiveNames.indexOf(item.issueNo) === -1 }" :name="item.issueNo"-->
<!--                                       :key="index">-->
<!--                        <template #title>-->
<!--                            <div class="basketball_day_title">-->
<!--                                <div class="basketball_day_title_a">-->
<!--								<span class="basketball_day_title_a_date">-->
<!--									{{item.issueNo}}-->
<!--								</span>-->
<!--                                    <span class="basketball_day_title_a_week">-->
<!--									{{fIsToday(item.issueNo) ? "今日" : item.weekStr}}-->
<!--								</span>-->
<!--                                </div>-->
<!--                                <span v-show="nActiveNames.indexOf(item.issueNo) !== -1" class="basketball_day_title_b">-->
<!--								共<em>{{item.gameNum}}</em>场比赛-->
<!--							</span>-->
<!--                            </div>-->
<!--                            <div v-show="nActiveNames.indexOf(item.issueNo) === -1" class="basketball_day_close">-->
<!--                                当日共 <em>{{item.gameNum}}</em> 场比赛-->
<!--                            </div>-->
<!--                        </template>-->
<!--                        <template #right-icon>-->
<!--                            <div class="basketball_day_rIcon" v-show="nActiveNames.indexOf(item.issueNo) !== -1">-->
<!--                                <span class="basketball_day_rIcon_text">收起</span>-->
<!--                                <img class="basketball_day_rIcon_icon" src="~@/assets/icon/icon-009.png">-->
<!--                            </div>-->
<!--                            <div class="basketball_day_rIcon" v-show="nActiveNames.indexOf(item.issueNo) === -1">-->
<!--                                <span class="basketball_day_rIcon_text">展开</span>-->
<!--                                <img class="basketball_day_rIcon_icon" src="~@/assets/icon/icon-010.png">-->
<!--                            </div>-->
<!--                        </template>-->
<!--                        <div class="basketball_options" v-for="(itemA, indexA) in item.responseList"-->
<!--                             :key="indexA">-->
<!--                            <div class="basketball_options_title">-->
<!--                                <div class="basketball_options_title_a">-->
<!--                                    <span>{{itemA.guestShortName}}(客)</span>-->
<!--                                    <img src="~@/assets/icon/icon-011.png">-->
<!--                                    <span>{{itemA.hostShortName}}(主)</span>-->
<!--                                </div>-->
<!--                                <div class="basketball_options_title_b">{{itemA.deadlineStr}}截止</div>-->
<!--                                <div class="basketball_options_title_c">-->
<!--                                    <img class="basketball_options_title_c_lIcon" src="~@/assets/icon/icon-013.png">-->
<!--                                    <div>-->
<!--                                        <em>{{itemA.gameNo}}</em>-->
<!--                                        <span>{{itemA.gameName}}</span>-->
<!--                                    </div>-->
<!--                                    <img class="basketball_options_title_c_rIcon" src="~@/assets/icon/icon-014.png">-->
<!--                                </div>-->
<!--                            </div>-->

<!--                            <div class="basketball_options_content">-->
<!--                                <div class="basketball_options_content_two">-->
<!--                                    <div class="basketball_options_content_two_type">胜负</div>-->
<!--                                    <div class="basketball_options_content_two_typeOption" v-if="itemA.supportSfGg">-->
<!--                                        <template v-for="(itemB,indexB) in oCPData.basketballSfResponse" :key="indexB">-->
<!--                                            <div :class="{'basketball_check': itemA.basketballSfResponse && itemA.basketballSfResponse[itemB.select],-->
<!--											'basketball_single' : indexB === 0 && itemA.supportSfDg}"-->

<!--                                                 @click="fSelectOptions(index,indexA,itemB,'basketballSfResponse')">-->
<!--                                                <p>{{itemB.name}}</p>-->
<!--                                                <p>-->
<!--                                                    {{itemA.basketballSfResponse && itemA.basketballSfResponse[itemB.key]}}-->
<!--                                                </p>-->
<!--                                            </div>-->
<!--                                        </template>-->
<!--                                    </div>-->
<!--                                    <div v-else class="basketball_options_none">-->
<!--                                        当前玩法不支持投注-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="basketball_options_content_two">-->
<!--                                    <div class="basketball_options_content_two_type">让分</div>-->
<!--                                    <div class="basketball_options_content_two_typeOption" v-if="itemA.supportRfsfGg">-->
<!--                                        <template v-for="(itemB,indexB) in oCPData.basketballRfsfResponse" :key="indexB">-->
<!--                                            <div :class="{'basketball_check': itemA.basketballRfsfResponse && itemA.basketballRfsfResponse[itemB.select],-->
<!--											'basketball_single' : indexB === 0 && itemA.supportRfsfDg}"-->
<!--                                                 @click="fSelectOptions(index,indexA,itemB,'basketballRfsfResponse')">-->
<!--                                                <p>{{itemB.name}} <em v-if="indexB === 1" class="basketball_options_badge">{{itemA.basketballRfsfResponse["pointSpread"]}}</em></p>-->
<!--                                                <p>-->
<!--                                                    {{itemA.basketballRfsfResponse && itemA.basketballRfsfResponse[itemB.key]}}-->
<!--                                                </p>-->
<!--                                            </div>-->
<!--                                        </template>-->
<!--                                    </div>-->
<!--                                    <div v-else class="basketball_options_none">-->
<!--                                        当前玩法不支持投注-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="basketball_options_content_two">-->
<!--                                    <div class="basketball_options_content_two_type">大小分</div>-->
<!--                                    <div class="basketball_options_content_two_typeOption" v-if="itemA.supportDxfGg">-->
<!--                                        <template v-for="(itemB,indexB) in oCPData.basketballDxfResponse" :key="indexB">-->
<!--                                            <div :class="{'basketball_check': itemA.basketballDxfResponse && itemA.basketballDxfResponse[itemB.select],-->
<!--											'basketball_single' : indexB === 0 && itemA.supportDxfDg}"-->
<!--                                                 @click="fSelectOptions(index,indexA,itemB,'basketballDxfResponse')">-->
<!--                                                <p>{{itemB.name}} <em class="basketball_options_badgeA">{{itemA.basketballDxfResponse["totalPoints"]}}</em></p>-->
<!--                                                <p>-->
<!--                                                    {{itemA.basketballDxfResponse && itemA.basketballDxfResponse[itemB.key]}}-->
<!--                                                </p>-->
<!--                                            </div>-->
<!--                                        </template>-->
<!--                                    </div>-->
<!--                                    <div v-else class="basketball_options_none">-->
<!--                                        当前玩法不支持投注-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="basketball_options_other"-->
<!--                                     :class="{'basketball_options_other_select' : itemA.selectMore > 0}"-->
<!--                                     @click="fOpenMore(index,indexA,itemA)">更多选项</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </van-collapse-item>-->
<!--                </van-collapse>-->
<!--            </div>-->
<!--            <div v-else class="basketball_none app_scroll">-->
<!--                <van-empty class="basketball_none_empty" :image="require('@/assets/images/img_021.png')"-->
<!--                           description="暂无赛事" />-->
<!--            </div>-->

<!--            <div class="basketball_bottom">-->
<!--                <div class="basketball_bottom_left">-->
<!--&lt;!&ndash;                    <img src="~@/assets/icon/icon-015.png">&ndash;&gt;-->
<!--                    <span v-show="aMatchSelect.length >0">已选 <em class="footBall_bottom_left_em2">{{aMatchSelect.length}}</em> 场</span>-->
<!--                    <span v-show="aMatchSelect.length === 0">非 <em class="basketball_bottom_left_em1">单关</em> 至少选择 <em-->
<!--                            class="basketball_bottom_left_em2">2</em>-->
<!--					场比赛</span>-->
<!--                </div>-->
<!--                <span class="basketball_bottom_right" :class="{'basketball_bottom_right&#45;&#45;disabled': bIsDisabled1}"-->
<!--                      @click="fBet1">-->
<!--				立即投注-->
<!--			</span>-->
<!--            </div>-->

<!--            <h-dialog-b :title="dialogB.title" dialogKey="dialogB" :show="dialogB.showDialog"-->
<!--                        :showCancelButton="dialogB.showCancelButton" :confirmButtonText="dialogB.confirmButtonText"-->
<!--                        @fBeforeClose="fBeforeClose">-->
<!--                <div class="basketball_allOption app_scroll">-->
<!--&lt;!&ndash;                    <van-button type="primary" @click="fSelectAll">全选</van-button>&ndash;&gt;-->
<!--                    <div class="basketball_allOption_way">-->
<!--                        <div class="basketball_allOption_way_title">-->
<!--                            胜负 / 让分胜负-->
<!--                        </div>-->
<!--                        <div class="basketball_allOption_way_content">-->
<!--                            <div class="basketball_allOption_way_content_two">-->
<!--                                <div class="basketball_allOption_way_content_two_typeSF">0</div>-->
<!--                                <div v-if="dialogB.match.supportSfGg" class="basketball_allOption_way_content_two_typeOptions-->
<!--								basketball_allOption_way_content_two_typeSFOptions">-->
<!--                                    <template v-for="(itemB,indexB) in oCPData.basketballSfResponse" :key="indexB">-->
<!--                                        <div :class="{'basketball_check': dialogB.match.basketballSfResponse && dialogB.match.basketballSfResponse[itemB.select],-->
<!--										'basketball_single' : indexB === 0 && dialogB.match.supportSfDg}"-->
<!--                                             @click="fSelectMoreOptions(itemB, 'basketballSfResponse')">-->
<!--                                            <p>{{itemB.name}}</p>-->
<!--                                            <p>-->
<!--                                                {{dialogB.match.basketballSfResponse && dialogB.match.basketballSfResponse[itemB.key]}}-->
<!--                                            </p>-->
<!--                                        </div>-->
<!--                                    </template>-->
<!--                                </div>-->
<!--                                <div v-else class="basketball_allOption_none basketball_allOption_noneAB">-->
<!--                                    当前玩法不支持投注-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="basketball_allOption_way_content_two">-->
<!--                                <div class="basketball_allOption_way_content_two_typeRFSF">让分</div>-->
<!--                                <div v-if="dialogB.match.supportRfsfGg"-->
<!--                                     class="basketball_allOption_way_content_two_typeOptions basketball_allOption_way_content_two_typeRFSFOptions">-->
<!--                                    <template v-for="(itemB,indexB) in oCPData.basketballRfsfResponse" :key="indexB">-->
<!--                                        <div :class="{'basketball_check': dialogB.match.basketballRfsfResponse && dialogB.match.basketballRfsfResponse[itemB.select],-->
<!--										'basketball_single' : indexB === 0 && dialogB.match.supportRfsfDg}"-->
<!--                                             @click="fSelectMoreOptions(itemB, 'basketballRfsfResponse')">-->
<!--                                            <p>{{itemB.name}} <em v-if="indexB === 1" class="basketball_options_badge">{{dialogB.match.basketballRfsfResponse["pointSpread"]}}</em></p>-->
<!--                                            <p>-->
<!--                                                {{dialogB.match.basketballRfsfResponse && dialogB.match.basketballRfsfResponse[itemB.key]}}-->
<!--                                            </p>-->
<!--                                        </div>-->
<!--                                    </template>-->
<!--                                </div>-->
<!--                                <div v-else class="basketball_allOption_none basketball_allOption_noneCD">-->
<!--                                    当前玩法不支持投注-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="basketball_allOption_way">-->
<!--                        <div class="basketball_allOption_way_title">-->
<!--                            <img v-if="dialogB.match.supportSfDg" src="~@/assets/icon/icon-012.png"> 大小分-->
<!--                        </div>-->
<!--                        <div class="basketball_allOption_way_content">-->
<!--                            <div class="basketball_allOption_way_content_bf">-->
<!--                                <div class="basketball_allOption_way_content_bf_type">平</div>-->
<!--                                <div v-if="dialogB.match.supportDxfGg"-->
<!--                                     class="basketball_allOption_way_content_bf_typeOptions">-->
<!--                                    <div :class="{'basketball_check': dialogB.match.basketballDxfResponse && dialogB.match.basketballDxfResponse[oCPData.basketballDxfResponse[0].select]}"-->
<!--                                         @click="fSelectMoreOptions(oCPData.basketballDxfResponse[0], 'basketballDxfResponse')">-->
<!--                                        <p>{{oCPData.basketballDxfResponse[0].name}}</p>-->
<!--                                        <p>-->
<!--                                            {{dialogB.match.basketballDxfResponse && dialogB.match.basketballDxfResponse[oCPData.basketballDxfResponse[0].key]}}-->
<!--                                        </p>-->
<!--                                    </div>-->
<!--                                    <span class="basketball_position">-->
<!--									{{dialogB.match.basketballDxfResponse["totalPoints"]}}-->
<!--								</span>-->
<!--                                    <div :class="{'basketball_check': dialogB.match.basketballDxfResponse && dialogB.match.basketballDxfResponse[oCPData.basketballDxfResponse[1].select]}"-->
<!--                                         @click="fSelectMoreOptions(oCPData.basketballDxfResponse[1], 'basketballDxfResponse')">-->
<!--                                        <p>{{oCPData.basketballDxfResponse[1].name}}</p>-->
<!--                                        <p>-->
<!--                                            {{dialogB.match.basketballDxfResponse && dialogB.match.basketballDxfResponse[oCPData.basketballDxfResponse[1].key]}}-->
<!--                                        </p>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div v-else class="basketball_allOption_none app_h44">-->
<!--                                    当前玩法不支持投注-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="basketball_allOption_way">-->
<!--                        <div class="basketball_allOption_way_title">-->
<!--                            <img v-if="dialogB.match.supportSfcDg" src="~@/assets/icon/icon-012.png"> 胜分差-->
<!--                        </div>-->

<!--                        <div class="basketball_allOption_way_content">-->
<!--                            <div class="basketball_allOption_way_content_three">-->
<!--                                <div class="basketball_allOption_way_content_three_typeSFC">-->
<!--								<span>-->
<!--									客胜-->
<!--								</span>-->
<!--                                    <span>-->
<!--									主胜-->
<!--								</span>-->
<!--                                </div>-->

<!--                                <div v-if="dialogB.match.supportSfcGg"-->
<!--                                     class="basketball_allOption_way_content_three_typeOptions">-->
<!--                                    <template v-for="(itemB,indexB) in oCPData.jcwJclqSfcResponseK" :key="indexB">-->
<!--                                        <div :class="{'basketball_check': dialogB.match.basketballSfcResponse && dialogB.match.basketballSfcResponse[itemB.select]}"-->
<!--                                             @click="fSelectMoreOptions(itemB, 'basketballSfcResponse')">-->
<!--                                            <p>{{itemB.name}}</p>-->
<!--                                            <p>-->
<!--                                                {{dialogB.match.basketballSfcResponse && dialogB.match.basketballSfcResponse[itemB.key]}}-->
<!--                                            </p>-->
<!--                                        </div>-->
<!--                                    </template>-->
<!--                                    <template v-for="(itemB,indexB) in oCPData.jcwJclqSfcResponseZ" :key="indexB">-->
<!--                                        <div :class="{'basketball_check': dialogB.match.basketballSfcResponse && dialogB.match.basketballSfcResponse[itemB.select]}"-->
<!--                                             @click="fSelectMoreOptions(itemB, 'basketballSfcResponse')">-->
<!--                                            <p>{{itemB.name}}</p>-->
<!--                                            <p>-->
<!--                                                {{dialogB.match.basketballSfcResponse && dialogB.match.basketballSfcResponse[itemB.key]}}-->
<!--                                            </p>-->
<!--                                        </div>-->
<!--                                    </template>-->
<!--                                </div>-->
<!--                                <div v-else class="basketball_allOption_none">-->
<!--                                    当前玩法不支持投注-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                </div>-->
<!--            </h-dialog-b>-->
<!--            <h-dialog-c title="提示" dialogKey="dialogC" :show="dialogC.showDialog"-->
<!--                        :showCancelButton="dialogC.showCancelButton" :confirmButtonText="dialogC.confirmButtonText"-->
<!--                        @fBeforeClose="fBeforeClose">-->
<!--                <div class="footBall_tips">-->
<!--                    当前有未提交的购彩信息，如果执行返回您将自动放弃购彩信息！-->
<!--                </div>-->
<!--            </h-dialog-c>-->
<!--        </div>-->
    </div>
</template>

<script>
    import {
        ref,
        reactive,
        computed
    } from 'vue';
    import {
        useStore
    } from "vuex";
    import {
        useRouter,
        // onBeforeRouteLeave
    } from "vue-router";
    import {
        Toast
    } from 'vant';
    import HDialogB from "@/components/HDialog/HDialogB/index.vue";
    // import HDialogC from "@/components/HDialog/HDialogC/index.vue";
    import Basketball from "@/views/allPlay/basketball.vue";
    import tipsContent from '@/utils/tipsContent';
    import {
        fnIsHasHistory,
        fnGoGeneralTxt
    } from "@/utils/router.js";
    import {
        fnDeepClone,
        fnIsToday,
        fnObjForArraySort,
        uaFun
    } from "@/utils/public.js";
    import {
        saveAgree
    } from '@/api/plan.js';
    import {
        apiJCZQ,
        // apiJCLQ
    } from '@/api/allplay.js';

    export default {
        name: "match",
        components: {
            HDialogB,
            // HDialogC,
            Basketball
        },
        setup() {
            const isApp = uaFun()
            const router = useRouter(); //初始化路由
            const store = useStore(); // 使用useStore方法
            const fIsToday = fnIsToday;
            const UserInfo = store.state.userInfo.userInfo;
            //离开当前的组件，触发
            // onBeforeRouteLeave((to) => {
            //     if (!dialogC.isSure) {
            //         // 白名单
            //         const WHITE_LIST = ['/bet', '/generalTxt'];
            //         // 确定返回
            //         if (WHITE_LIST.indexOf(to.path) === -1) {
            //             // 前往地址是否为投注地址--否
            //             if (aMatchSelect.value.length > 0) {
            //                 // 赛事选项是否存在--是
            //                 const timer = setTimeout(() => {
            //                     dialogC.showDialog = true;
            //                     clearTimeout(timer);
            //                 }, 100)
            //                 return false;
            //             }
            //         }
            //     } else {
            //         // 确定返回--是
            //         store.dispatch("allPlay/UFBSelected", []);
            //     }
            // })
            const oCPData = reactive({
                oNCData: {},
                oYCData: {
                    dialogCA: {
                        showDialog: false,
                        showCancelButton: true,
                        confirmButtonText: "确定"
                    },
                    dialogCB: {
                        showDialog: false,
                        showCancelButton: false,
                        confirmButtonText: "确定"
                    },
                    dialogCC: {
                        showDialog: false,
                        showCancelButton: false,
                        confirmButtonText: "去支付佣金"
                    },
                    dialogCD: {
                        showDialog: false,
                        showCancelButton: true,
                        confirmButtonText: "确定"
                    }
                },
                bang:{
                    isBang:false,
                    styleBang2:'font-size: 0.373rem;font-weight: 400',
                    styleBang1:'',
                },
                // 让球胜平负
                footballSpfResponse: [
                    {
                    name: '胜',
                    key: 'hostWinSp',
                    select: 'hostWinSpSelect',
                    sort: 0
                },
                    {
                        name: '平',
                        key: 'drawSp',
                        select: 'drawSpSelect',
                        sort: 1
                    },
                    {
                        name: '负',
                        key: 'guestWinSp',
                        select: 'guestWinSpSelect',
                        sort: 2
                    }
                ],
                // 让球胜平负
                footballRqspfResponse: [
                    {
                    name: '胜',
                    key: 'hostRqWinSp',
                    select: 'hostRqWinSpSelect',
                    sort: 3
                },
                    {
                        name: '平',
                        key: 'rqDrawSp',
                        select: 'rqDrawSpSelect',
                        sort: 4
                    },
                    {
                        name: '负',
                        key: 'guestRqWinSp',
                        select: 'guestRqWinSpSelect',
                        sort: 5
                    }
                ],
                // 比分主胜
                bfZSMatchResponse: [
                    {
                    name: '1:0',
                    key: 's01s00',
                    select: 's01s00Select',
                    sort: 6
                },
                    {
                        name: '2:0',
                        key: 's02s00',
                        select: 's02s00Select',
                        sort: 7
                    },
                    {
                        name: '2:1',
                        key: 's02s01',
                        select: 's02s01Select',
                        sort: 8
                    },
                    {
                        name: '3:0',
                        key: 's03s00',
                        select: 's03s00Select',
                        sort: 9
                    },
                    {
                        name: '3:1',
                        key: 's03s01',
                        select: 's03s01Select',
                        sort: 10
                    },
                    {
                        name: '3:2',
                        key: 's03s02',
                        select: 's03s02Select',
                        sort: 11
                    },
                    {
                        name: '4:0',
                        key: 's04s00',
                        select: 's04s00Select',
                        sort: 12
                    },
                    {
                        name: '4:1',
                        key: 's04s01',
                        select: 's04s01Select',
                        sort: 13
                    },
                    {
                        name: '4:2',
                        key: 's04s02',
                        select: 's04s02Select',
                        sort: 14
                    },
                    {
                        name: '5:0',
                        key: 's05s00',
                        select: 's05s00Select',
                        sort: 15
                    },
                    {
                        name: '5:1',
                        key: 's05s01',
                        select: 's05s01Select',
                        sort: 16
                    },
                    {
                        name: '5:2',
                        key: 's05s02',
                        select: 's05s02Select',
                        sort: 17
                    },
                    {
                        name: '胜其他',
                        key: 's1sh',
                        select: 's1shSelect',
                        sort: 18
                    }
                ],
                // 比分平
                bfPMatchResponse: [
                    {
                    name: '0:0',
                    key: 's00s00',
                    select: 's00s00Select',
                    sort: 19
                },
                    {
                        name: '1:1',
                        key: 's01s01',
                        select: 's01s01Select',
                        sort: 20
                    },
                    {
                        name: '2:2',
                        key: 's02s02',
                        select: 's02s02Select',
                        sort: 21
                    },
                    {
                        name: '3:3',
                        key: 's03s03',
                        select: 's03s03Select',
                        sort: 22
                    },
                    {
                        name: '平其他',
                        key: 's1sd',
                        select: 's1sdSelect',
                        sort: 23
                    }
                ],
                // 比分主负
                bfZFMatchResponse: [
                    {
                    name: '0:1',
                    key: 's00s01',
                    select: 's00s01Select',
                    sort: 24
                },
                    {
                        name: '0:2',
                        key: 's00s02',
                        select: 's00s02Select',
                        sort: 25
                    },
                    {
                        name: '1:2',
                        key: 's01s02',
                        select: 's01s02Select',
                        sort: 26
                    },
                    {
                        name: '0:3',
                        key: 's00s03',
                        select: 's00s03Select',
                        sort: 27
                    },
                    {
                        name: '1:3',
                        key: 's01s03',
                        select: 's01s03Select',
                        sort: 28
                    },
                    {
                        name: '2:3',
                        key: 's02s03',
                        select: 's02s03Select',
                        sort: 29
                    },
                    {
                        name: '0:4',
                        key: 's00s04',
                        select: 's00s04Select',
                        sort: 30
                    },
                    {
                        name: '1:4',
                        key: 's01s04',
                        select: 's01s04Select',
                        sort: 31
                    },
                    {
                        name: '2:4',
                        key: 's02s04',
                        select: 's02s04Select',
                        sort: 32
                    },
                    {
                        name: '0:5',
                        key: 's00s05',
                        select: 's00s05Select',
                        sort: 33
                    },
                    {
                        name: '1:5',
                        key: 's01s05',
                        select: 's01s05Select',
                        sort: 34
                    },
                    {
                        name: '2:5',
                        key: 's02s05',
                        select: 's02s05Select',
                        sort: 35
                    },
                    {
                        name: '负其他',
                        key: 's1sa',
                        select: 's1saSelect',
                        sort: 36
                    }
                ],
                // 进球数
                footballJqsResponse: [
                    {
                    name: '0',
                    key: 's0',
                    select: 's0Select',
                    sort: 37
                },
                    {
                        name: '1',
                        key: 's1',
                        select: 's1Select',
                        sort: 38
                    },
                    {
                        name: '2',
                        key: 's2',
                        select: 's2Select',
                        sort: 39
                    },
                    {
                        name: '3',
                        key: 's3',
                        select: 's3Select',
                        sort: 40
                    },
                    {
                        name: '4',
                        key: 's4',
                        select: 's4Select',
                        sort: 41
                    },
                    {
                        name: '5',
                        key: 's5',
                        select: 's5Select',
                        sort: 42
                    },
                    {
                        name: '6',
                        key: 's6',
                        select: 's6Select',
                        sort: 43
                    },
                    {
                        name: '7+',
                        key: 's7',
                        select: 's7Select',
                        sort: 44
                    }
                ],
                // 半全场
                footballBqcspfResponse: [
                    {
                    name: '胜-胜',
                    key: 'hh',
                    select: 'hhSelect',
                    sort: 45
                },
                    {
                        name: '胜-平',
                        key: 'hd',
                        select: 'hdSelect',
                        sort: 46
                    },
                    {
                        name: '胜-负',
                        key: 'ha',
                        select: 'haSelect',
                        sort: 47
                    },
                    {
                        name: '平-胜',
                        key: 'dh',
                        select: 'dhSelect',
                        sort: 48
                    },
                    {
                        name: '平-平',
                        key: 'dd',
                        select: 'ddSelect',
                        sort: 49
                    },
                    {
                        name: '平-负',
                        key: 'da',
                        select: 'daSelect',
                        sort: 50
                    },
                    {
                        name: '负-胜',
                        key: 'ah',
                        select: 'ahSelect',
                        sort: 51
                    },
                    {
                        name: '负-平',
                        key: 'ad',
                        select: 'adSelect',
                        sort: 52
                    },
                    {
                        name: '负-负',
                        key: 'aa',
                        select: 'aaSelect',
                        sort: 53
                    }
                ],



                // 胜负
                basketballSfResponse: [
                    {
                        name: '客胜',
                        key: 'aSfSp',
                        select: 'aSfSpSelect',
                        sort: 0
                    },
                    {
                        name: '主胜',
                        key: 'hSfSp',
                        select: 'hSfSpSelect',
                        sort: 1
                    }
                ],
                // 让分胜负
                basketballRfsfResponse: [
                    {
                        name: '客胜',
                        key: 'aRfsfSp',
                        select: 'aRfsfSpSelect',
                        sort: 2
                    }, {
                        name: '主胜',
                        key: 'hRfzsSp',
                        select: 'hRfzsSpSelect',
                        sort: 3
                    }],
                // 大小分
                basketballDxfResponse: [
                    {
                        name: '大于',
                        key: 'hDxfSp',
                        select: 'hDxfSpSelect',
                        sort: 4
                    }, {
                        name: '小于',
                        key: 'lDxfSp',
                        select: 'lDxfSpSelect',
                        sort: 5
                    }],
                //胜分差客胜
                jcwJclqSfcResponseK: [
                    {
                        name: '客胜1~5',
                        key: 'l1SfcSp',
                        select: 'l1SfcSpSelect',
                        sort: 6
                    },
                    {
                        name: '客胜6~10',
                        key: 'l2SfcSp',
                        select: 'l2SfcSpSelect',
                        sort: 7
                    },
                    {
                        name: '客胜11~15',
                        key: 'l3SfcSp',
                        select: 'l3SfcSpSelect',
                        sort: 8
                    },
                    {
                        name: '客胜16~20',
                        key: 'l4SfcSp',
                        select: 'l4SfcSpSelect',
                        sort: 9
                    },
                    {
                        name: '客胜21~25',
                        key: 'l5SfcSp',
                        select: 'l5SfcSpSelect',
                        sort: 10
                    },
                    {
                        name: '客胜26+',
                        key: 'l6SfcSp',
                        select: 'l6SfcSpSelect',
                        sort: 11
                    }
                ],
                //胜分差主胜
                jcwJclqSfcResponseZ: [
                    {
                        name: '主胜1~5',
                        key: 'w1SfcSp',
                        select: 'w1SfcSpSelect',
                        sort: 12
                    },
                    {
                        name: '主胜6~10',
                        key: 'w2SfcSp',
                        select: 'w2SfcSpSelect',
                        sort: 13
                    },
                    {
                        name: '主胜11~15',
                        key: 'w3SfcSp',
                        select: 'w3SfcSpSelect',
                        sort: 14
                    },
                    {
                        name: '主胜16~20',
                        key: 'w4SfcSp',
                        select: 'w4SfcSpSelect',
                        sort: 15
                    },
                    {
                        name: '主胜21~25',
                        key: 'w5SfcSp',
                        select: 'w5SfcSpSelect',
                        sort: 16
                    },
                    {
                        name: '主胜26+',
                        key: 'w6SfcSp',
                        select: 'w6SfcSpSelect',
                        sort: 17
                    }
                ],

            });
            // const load = () =>{
            //
            // };
            const bangFun = () => {
                oCPData.bang.isBang = false
                oCPData.bang.styleBang2 = 'font-size: 0.373rem;font-weight: 400'
                oCPData.bang.styleBang1= ''
                fGetMatch()

            };
            const bangFun1 = () => {
                oCPData.bang.isBang = true
                oCPData.bang.styleBang1 = 'font-size: 0.373rem;font-weight: 400'
                oCPData.bang.styleBang2= ''
                // apiJCLQMatch()

            };
            // 更多选项弹框
            const dialogB = reactive({
                title: {
                    master: "主队",
                    guest: "客队"
                },
                showDialog: false,
                showCancelButton: true,
                confirmButtonText: "确定",
                index: -1,
                indexA: -1,
                match: {}
            });
            // dialogC
            const dialogC = reactive({
                isSure: false, // 确定退出
                showDialog: false,
                showCancelButton: true,
                confirmButtonText: "继续返回"
            });

            // van-collapse的唯一值
            const nActiveNames = ref([]);
            // 是否禁用立即投注按钮
            const bIsDisabled = computed(() => {
                console.log(aMatchSelect)
                const tAMatchSelect = aMatchSelect.value;
                let tBIsDisabled = true

                if (tAMatchSelect.length > 1) {
                    tBIsDisabled = false;
                } else if (tAMatchSelect.length === 1) {
                    // 该数据选中非单关不存在，并且单关存在
                    if (tAMatchSelect[0].selectNoDg <= 0 && tAMatchSelect[0].selectDg > 0) {
                        tBIsDisabled = false;
                    } else {
                        tBIsDisabled = true;
                    }
                } else {
                    tBIsDisabled = true;
                }
                return tBIsDisabled
            });
            // const bIsDisabled1 = computed(() => {
            //     console.log(aMatchSelect)
            //     const tAMatchSelect = aMatchSelect.value;
            //     let tBIsDisabled = true
            //     if (tAMatchSelect.length > 1) {
            //         tBIsDisabled = false;
            //     } else if (tAMatchSelect.length === 1) {
            //         // 该数据选中非单关不存在，并且单关存在
            //         if (tAMatchSelect[0].selectNoDg <= 0 && tAMatchSelect[0].selectDg > 0) {
            //             tBIsDisabled = false;
            //         } else {
            //             tBIsDisabled = true;
            //         }
            //     } else {
            //         tBIsDisabled = true;
            //     }
            //     return tBIsDisabled
            // });
            // 赛事列表
            const aMatch = ref([]);
            // 赛事列表-备份
            const aMatch_BackUp = ref([]);
            // 已选择赛事信息
            const aMatchSelect = computed(() => {
                return store.state.allPlay.FBSelected
            });
            // 足球赛事
            const fGetMatch = () => {
                Toast({
                    type: "loading"
                });
                apiJCZQ({
                    roleId: UserInfo.roleId
                }).then(res => {
                    let tData = res.result;
                    aMatch.value = fnDeepClone(tData);
                    if (tData.length > 0) nActiveNames.value = [tData[0].issueNo],
                        aMatch_BackUp.value = fnDeepClone(tData);
                    fEcho();

                })
            };
            // 篮球赛事
            // const apiJCLQMatch = () => {
            //     Toast({
            //         type: "loading"
            //     });
            //     apiJCLQ({
            //         roleId: UserInfo.roleId
            //     }).then(res => {
            //         let tData = res.result;
            //         aMatch.value = fnDeepClone(tData);
            //         if (tData.length > 0) nActiveNames.value = [tData[0].issueNo],
            //             aMatch_BackUp.value = fnDeepClone(tData);
            //         fEcho1();
            //
            //     })
            // };
            fGetMatch();
            // apiJCLQMatch();


            // 数据回显
            // const fEcho1 = () => {
            //     console.log("开始回显");
            //     let tAMatchSelect = aMatchSelect.value;
            //     console.log(tAMatchSelect);
            //     const tAMatch = aMatch.value;
            //     let tCAMatchSelect = fnDeepClone(tAMatchSelect);
            //     if (tAMatchSelect.length === 0) return;
            //     console.log(tAMatchSelect)
            //
            //     tCAMatchSelect.forEach((itemA, indexA) => {
            //         let tNoSame = false;
            //         tAMatch.forEach(itemB => {
            //             itemB.jclqMatchDetailResponseList.forEach((itemC, indexC) => {
            //                 // 注意 注意 注意
            //                 // 如果赛事胜平负新的数据是单关，但是回显数据并不是单关，那么则删除
            //                 // 同理 如果赛事过关开关与回显数据不同，那么则删除
            //                 if (itemA.id === itemC.id &&
            //                     itemA.supportSfGg === itemC.supportSfGg &&
            //                     itemA.supportSfDg === itemC.supportSfDg &&
            //                     itemA.supportRfsfGg === itemC.supportRfsfGg &&
            //                     itemA.supportRfsfDg === itemC.supportRfsfDg &&
            //                     itemA.supportDxfGg === itemC.supportDxfGg &&
            //                     itemA.supportDxfDg === itemC.supportDxfDg &&
            //                     itemA.supportSfcGg === itemC.supportSfcGg &&
            //                     itemA.supportSfcDg === itemC.supportSfcDg) {
            //                     tNoSame = true;
            //                     // 如果玩法的过关开启，则合并选项，如果未开启，则使用新数据
            //                     itemA.jcwJclqSfResponse = itemC.supportSfGg ? {
            //                         ...itemA.jcwJclqSfResponse,
            //                         ...itemC.jcwJclqSfResponse
            //                     } : itemC.jcwJclqSfResponse;
            //                     itemA.jcwJclqRfsfResponse = itemC.supportRfsfGg ? {
            //                         ...itemA.jcwJclqRfsfResponse,
            //                         ...itemC.jcwJclqRfsfResponse
            //                     } : itemC.jcwJclqRfsfResponse;
            //                     itemA.jcwJclqDxfResponse = itemC.supportDxfGg ? {
            //                         ...itemA.jcwJclqDxfResponse,
            //                         ...itemC.jcwJclqDxfResponse
            //                     } : itemC.jcwJclqDxfResponse;
            //                     itemA.basketballSfcResponse = itemC.supportSfcGg ? {
            //                         ...itemA.basketballSfcResponse,
            //                         ...itemC.basketballSfcResponse
            //                     } : itemC.basketballSfcResponse;
            //                     itemB.jclqMatchDetailResponseList[indexC] = fnDeepClone(itemA);
            //                 }
            //             })
            //         })
            //         if (!tNoSame) delete tAMatchSelect[indexA];
            //     })
            //
            //     // 处理后的数据
            //     let tHandleData = tAMatchSelect.filter(item => item !== undefined);
            //
            //     tAMatchSelect = fnDeepClone(tHandleData);
            //     store.dispatch("allPlay/UBBSelected", fnDeepClone(tHandleData));
            //     console.log(aMatch.value)
            // }


            // 数据回显
            const fEcho = () => {
                console.log("开始回显");
                let tAMatchSelect = aMatchSelect.value;
                const tAMatch = aMatch.value;
                let tCAMatchSelect = fnDeepClone(tAMatchSelect);
                if (tAMatchSelect.length === 0) return;
                console.log(tAMatchSelect)

                tCAMatchSelect.forEach((itemA, indexA) => {
                    let tNoSame = false;
                    tAMatch.forEach(itemB => {
                        itemB.responseList.forEach((itemC, indexC) => {
                            // 注意 注意 注意
                            // 如果赛事胜平负新的数据是单关，但是回显数据并不是单关，那么则删除
                            // 同理 如果赛事过关开关与回显数据不同，那么则删除
                            if (itemA.id === itemC.id &&
                                itemA.isSupportBf === itemC.isSupportBf &&
                                itemA.isSupportBfDg === itemC.isSupportBfDg &&
                                itemA.isSupportBqcspf === itemC.isSupportBqcspf &&
                                itemA.isSupportBqcspfDg === itemC.isSupportBqcspfDg &&
                                itemA.isSupportJqs === itemC.isSupportJqs &&
                                itemA.isSupportJqsDg === itemC.isSupportJqsDg &&
                                itemA.isSupportRqspfDg === itemC.isSupportRqspfDg &&
                                itemA.isSupportRqspfGg === itemC.isSupportRqspfGg &&
                                itemA.isSupportSpfDg === itemC.isSupportSpfDg &&
                                itemA.isSupportSpfGg === itemC.isSupportSpfGg) {
                                tNoSame = true;
                                // 如果玩法的过关开启，则合并选项，如果未开启，则使用新数据
                                itemA.footballSpfResponse = itemC.isSupportSpfGg ? {
                                    ...itemA.footballSpfResponse,
                                    ...itemC.footballSpfResponse
                                } : itemC.footballSpfResponse;
                                itemA.footballRqspfResponse = itemC.isSupportRqspfGg ? {
                                    ...itemA.footballRqspfResponse,
                                    ...itemC.footballRqspfResponse
                                } : itemC.footballRqspfResponse;
                                itemA.footballBfResponse = itemC.isSupportBf ? {
                                    ...itemA.footballBfResponse,
                                    ...itemC.footballBfResponse
                                } : itemC.footballBfResponse;
                                itemA.footballJqsResponse = itemC.isSupportJqs ? {
                                    ...itemA.footballJqsResponse,
                                    ...itemC.footballJqsResponse
                                } : itemC.footballJqsResponse;
                                itemA.footballBqcspfResponse = itemC.isSupportBqcspf ? {
                                    ...itemA.footballBqcspfResponse,
                                    ...itemC.footballBqcspfResponse
                                } : itemC.footballBqcspfResponse;
                                itemB.responseList[indexC] = fnDeepClone(itemA);
                            }
                        })
                    })
                    if (!tNoSame) delete tAMatchSelect[indexA];
                })

                // 处理后的数据
                let tHandleData = tAMatchSelect.filter(item => item !== undefined);

                tAMatchSelect = fnDeepClone(tHandleData);
                store.dispatch("allPlay/UFBSelected", fnDeepClone(tHandleData));
                console.log(aMatch.value)
            }
            /**
             * [fSelectOptions 选择比赛选项]
             * @param  {[Number]} Index [所属期次号下标]
             * @param  {[Number]} IndexA [当前比赛下标]
             * @param  {[Object]} Option [当前比赛选中的选项信息]
             * @param  {[Object]} ObjName [当前比赛选项类型的对象名称]
             * @return {[Null]}
             */
            const fSelectOptions = (Index, IndexA, Option, ObjName) => {
                const tMatchToday = aMatch.value[Index];
                const tMatch = tMatchToday["responseList"][IndexA];
                tMatch["issueNo"] = tMatchToday["issueNo"];
                fnHandleSelectData("all", tMatch, Option, ObjName);
            };


            /**
             * [fnHandleSelectData 处理选中数据]
             * @param  {[String]} IsDo [是否开始 从aMatchSelect中合并或者移除]
             * @param  {[Object]} Match [当前赛事信息]
             * @param  {[Object]} Option [当前比赛选中的选项信息]
             * @param  {[Object]} ObjName [当前比赛选项类型的对象名称]
             * @return {[Null]}
             */
            const fnHandleSelectData = (IsDo, Match, Option, ObjName) => {

                console.log(IsDo, Match, Option, ObjName)
                if (IsDo === "all" || IsDo === "first") {
                    if (aMatchSelect.value.length === 8) {
                        // 当前赛事是否已添加到选项中
                        let tAMatchSelect = aMatchSelect.value;
                        // 是否存在
                        let tIsHas = tAMatchSelect.filter(item => item.id === Match.id);
                        if (tIsHas.length === 0 && !Match[ObjName][Option.select]) {
                            return Toast({
                                message: tipsContent.maxSession,
                                position: 'bottom'
                            });
                        }
                    }

                    Match[ObjName][Option.select] = !Match[ObjName][Option.select];
                    // 单关对应名字
                    let dgName = false;
                    // 设置单关对应字段
                    switch (ObjName) {
                        case "footballSpfResponse":
                            dgName = "isSupportSpfDg";
                            break;
                        case "footballRqspfResponse":
                            dgName = "isSupportRqspfDg";
                            break;
                        case "footballBfResponse":
                            dgName = "isSupportBfDg";
                            break;
                        case "footballJqsResponse":
                            dgName = "isSupportJqsDg";
                            break;
                        case "footballBqcspfResponse":
                            dgName = "isSupportBqcspfDg";
                            break;

                        case "basketballSfResponse":
                            dgName = "supportSfDg";
                            break;
                        case "basketballRfsfResponse":
                            dgName = "supportRfsfDg";
                            break;
                        case "basketballDxfResponse":
                            dgName = "supportDxfDg";
                            break;
                        case "basketballSfcResponse":
                            dgName = "supportSfcDg";
                            break;
                        default:
                            dgName = false;
                            break;
                    }

                    // 计算已选中
                    Match.selectNum = Match.selectNum ? Match.selectNum : 0;
                    // 计算已选中单关
                    Match.selectDg = Match.selectDg ? Match.selectDg : 0;
                    // 计算已选中非单关：用于判断只有一场赛事时是否同时选中单关和非单关选项
                    Match.selectNoDg = Match.selectNoDg ? Match.selectNoDg : 0;
                    // 计算已选中更多
                    Match.selectMore = Match.selectMore ? Match.selectMore : 0;

                    // 当前赛事选中的选项为true
                    if (Match[ObjName][Option.select]) {
                        console.log(Match[ObjName])
                        // 组装数据，方便投注页面使用
                        let tObj = {
                            name: Option.name,
                            award: Match[ObjName][Option.key],
                            key: Option.key,
                            sort: Option.sort,
                        };
                        if (Match[ObjName + "Select"] && Match[ObjName + "Select"].length >= 0) {
                            let tIsHasOption = Match[ObjName + "Select"].filter(item => item.key === tObj.key);
                            if (tIsHasOption.length === 0) Match[ObjName + "Select"].push(tObj);
                            fnObjForArraySort(Match[ObjName + "Select"], "sort");
                        } else {
                            Match[ObjName + "Select"] = [tObj];
                        }


                        Match.selectNum += 1;
                        // 判断当前赛事选中的选项是否支持单关
                        if (Match[dgName]) {
                            Match.selectDg += 1;
                        } else {
                            Match.selectNoDg += 1;
                        }
                    } else {
                        // 组装数据，方便投注页面使用
                        let tObj = {
                            name: Match[ObjName][Option.name],
                            award: Match[ObjName][Option.key],
                            key: Option.key,
                            sort: Option.sort,
                        };
                        if (Match[ObjName + "Select"] && Match[ObjName + "Select"].length >= 0) {
                            Match[ObjName + "Select"].forEach((item, index) => {
                                if (item.key === tObj.key) {
                                    delete Match[ObjName + "Select"][index]
                                }
                            });
                            Match[ObjName + "Select"] = fnDeepClone(Match[ObjName + "Select"].filter(item => item !==
                                undefined));
                        } else {
                            Match[ObjName + "Select"] = [];
                        }

                        if (Match.selectNum > 0) Match.selectNum -= 1;
                        // 判断当前赛事选中的选项是否支持单关
                        if (Match[dgName]) {
                            if (Match.selectDg > 0) Match.selectDg -= 1;
                        } else {
                            if (Match.selectNoDg > 0) Match.selectNoDg -= 1;
                        }
                    }

                    // 判断当前选中选项是否为更多选项中独有的选项
                    if (ObjName === 'footballBfResponse' || ObjName === 'footballJqsResponse' || ObjName ===
                        'footballBqcspfResponse') {
                        if (Match[ObjName][Option.select]) {
                            Match.selectMore += 1;
                        } else {
                            if (Match.selectMore > 0) Match.selectMore -= 1;
                        }
                    }
                }

                if (IsDo === "all" || IsDo === "second") {
                    let tMatch = fnDeepClone(Match)
                    // 当前赛事是否已添加到选项中
                    let tAMatchSelect = aMatchSelect.value;
                    // 是否已添加到选项中
                    let tIsHas = false;
                    for (let i = 0; i < tAMatchSelect.length; i++) {
                        if (tAMatchSelect[i].id === Match.id) {
                            // 当前赛事已选中数量大于0
                            if (Match.selectNum > 0) {
                                // 已添加则更新
                                tIsHas = true;
                                tAMatchSelect[i] = tMatch;
                            } else {
                                tAMatchSelect.splice(i, 1)
                                break;
                            }
                        }
                    }
                    // 当前赛事已选中数量大于0 并且 未添加则添加
                    if (Match.selectNum > 0 && !tIsHas) tAMatchSelect.push(tMatch);

                    console.log(aMatchSelect);
                }

            }

            /**
             * [fOpenMore 选择比赛选项]
             * @param  {[Number]} Index [所属期次号下标]
             * @param  {[Number]} IndexA [当前比赛下标]
             * @param  {[Object]} Match [当前比赛信息]
             * @return {[Null]}
             */
                // 更多选项弹框--打开 (赛事)
            const fOpenMore = (Index, IndexA, Match) => {
                    const tMatchToday = aMatch.value[Index];
                    Match["issueNo"] = tMatchToday["issueNo"];

                    console.log(Match);
                    dialogB.title = {
                        master: Match.hostShortName,
                        guest: Match.guestShortName
                    }
                    dialogB.showDialog = true;
                    dialogB.index = Index;
                    dialogB.indexA = IndexA;
                    dialogB.match = fnDeepClone(Match)
                    console.log(dialogB);
                };
            /**
             * [fSelectMoreOptions 选择比赛更多选项]
             * @param  {[Object]} Option [当前选中的选项信息]
             * @param  {[Object]} ObjName [当前比赛选项类型的对象名称]
             * @return {[Null]}
             */
            const fSelectMoreOptions = (Option, ObjName) => {
                fnHandleSelectData("first", dialogB.match, Option, ObjName)
            };
            // 更多选项弹框--关闭之前
            const fBeforeClose = (action, oDialog, fn) => {
                const {
                    dialogKey
                } = oDialog;
                console.log(action, oDialog, fn)
                if (dialogKey === "dialogB") {
                    if (action === "confirm") {
                        console.log("确定选择");
                        fnHandleSelectData("second", dialogB.match);
                        aMatch.value[dialogB.index]["responseList"][dialogB.indexA] = fnDeepClone(dialogB
                            .match);
                        dialogB.showDialog = false;
                        fn(false)
                    } else if (action === "cancel") {
                        console.log("关闭弹框");
                        dialogB.showDialog = false;
                        fn(false)
                    }
                } else if (dialogKey === "dialogC") {
                    if (action === "confirm") {
                        dialogC.showDialog = false;
                        dialogC.isSure = true;
                        fnIsHasHistory("/");
                        fn(false)
                    } else if (action === "cancel") {
                        dialogC.showDialog = false;
                        dialogC.isSure = false;
                        fn(false)
                    }
                }
            }

            // 清除所有
            const fCleanAll = () => {
                aMatch.value = fnDeepClone(aMatch_BackUp.value);
                aMatchSelect.value = [];
                store.dispatch("allPlay/UFBSelected", []);
            };
            // 立即投注
            const fBet = () => {
                console.log(aMatchSelect.value);
                if (bIsDisabled.value) return;
                store.dispatch("allPlay/UFBSelected", aMatchSelect.value);
                router.push({
                    path: '/bet'
                })
            };
            // const fBet1 = () => {
            //
            //     if (bIsDisabled1.value) return;
            //     debugger
            //     store.dispatch("allPlay/UBBSelected", aMatchSelect.value);
            //     store.dispatch("allPlay/UOrderData", {});
            //     router.push({
            //         path: '/betLQ'
            //     })
            //     console.log(123654);
            // };

            // fLeft van-nav-bar 左侧点击事件
            const fLeft = () => {
                if (aMatchSelect.value.length > 0) {
                    dialogC.showDialog = true;
                } else {
                    fnIsHasHistory("/");
                }
            };
            // fRight van-nav-bar 右侧点击事件
            // const fRight = () => {
            //     router.push({
            //         path: '/generalTxt',
            //         query: {
            //             agreement: 'playWayZQ'
            //         }
            //     })
            // };

            // 选择全部
            const fSelectAll = () => {
                console.log(dialogB.match);
                if (dialogB.match.isSupportSpfGg) {
                    oCPData.footballSpfResponse.forEach(item => {
                        fSelectMoreOptions(item, "footballSpfResponse")
                    })
                }
                if (dialogB.match.isSupportRqspfGg) {
                    oCPData.footballRqspfResponse.forEach(item => {
                        fSelectMoreOptions(item, "footballSpfResponse")
                    })
                }
                if (dialogB.match.isSupportBf) {
                    oCPData.bfZSMatchResponse.forEach(item => {
                        fSelectMoreOptions(item, "footballSpfResponse")
                    })
                }
                if (dialogB.match.isSupportBf) {
                    oCPData.bfPMatchResponse.forEach(item => {
                        fSelectMoreOptions(item, "footballSpfResponse")
                    })
                }
                if (dialogB.match.isSupportBf) {
                    oCPData.bfZFMatchResponse.forEach(item => {
                        fSelectMoreOptions(item, "footballBfResponse")
                    })
                }
                if (dialogB.match.isSupportJqs) {
                    oCPData.footballJqsResponse.forEach(item => {
                        fSelectMoreOptions(item, "footballJqsResponse")
                    })
                }
                if (dialogB.match.isSupportBqcspf) {
                    oCPData.footballBqcspfResponse.forEach(item => {
                        fSelectMoreOptions(item, "footballBqcspfResponse")
                    })
                }
            }
            const dianzFun = (e,isZan) =>{
                Toast({
                    type: "loading"
                });
                saveAgree({
                    agreeSign:isZan,
                    entityId:e.id,
                    roleId:UserInfo.roleId,
                    type:"MATCH",
                    userId:UserInfo.userId
                }).then(()=>{
                    fGetMatch()
                })
            };
            const toPl = (e) =>{
                router.push({
                    path: '/detailMatch',
                    query: {
                        entityId:e,
                        type:'JCZQ'
                    }
                })
            }

            return {
                // load,
                bangFun,
                bangFun1,
                dianzFun,
                oCPData,
                toPl,
                nActiveNames,
                fnGoGeneralTxt,
                bIsDisabled,
                // bIsDisabled1,
                dialogC,
                dialogB,
                aMatch,
                aMatchSelect,
                isApp,

                fSelectOptions,
                fOpenMore,
                fSelectMoreOptions,
                fBeforeClose,

                fCleanAll,
                fBet,
                // fBet1,
                fLeft,
                // fRight,
                fIsToday,

                fSelectAll
            }
        }
    }
</script>

<style scoped lang="scss">
    .match{
        width: 100%;
        height: 100%;
        background: #F1F2F7;
        padding-top: 1.247rem;
        box-sizing: border-box;
        .gendan{
            width: calc(100% - 0.64rem);
            margin: 0 auto;
            background: #F1F2F7;
            /*background: #FAFBFF;*/
            /*box-shadow: 0px 0.053rem 0.427rem 0px rgba(224,228,246,0.5);*/
            /*border-radius: 0.32rem;*/
            position: fixed;
            left: 0.32rem;
            top: 0;
            z-index: 999;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .bang_top{
                width: 100%;
                height: 1.147rem;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                margin-bottom: 0.4rem;
                .bang_title_box{
                    /*width: 1.707rem;*/
                    /*height: 100%;*/
                    .bang_title{
                        /*width: 1.707rem;*/
                        height: 0.587rem;
                        font-size: 0.427rem;
                        font-weight: 600;
                        color: #383838;
                        line-height: 0.587rem;
                        position: relative;
                        img{
                            width: 0.507rem;
                            height: 0.16rem;
                            position: absolute;
                            left: calc((100% - 0.507rem)/2);
                            bottom: -0.187rem;
                        }
                    }

                }
            }
            .gz{
                width: 0.373rem;
                height: 0.373rem;
            }


        }

    }
    .footBall {
        width: 100%;
        height: 100%;
        background: #F1F2F7;
        /*background-image: url('~@/assets/images/img_011.png');*/
        background-size: 100% auto;
        background-repeat: no-repeat;

        .footBall_none {
            margin: 0.2933rem 0.32rem 0;
            background: #FAFBFF;
            box-shadow: 0px 0.0533rem 0.4266rem 0px rgba(224, 228, 246, 0.5);
            border-radius: 0.2133rem 0.2133rem 0px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .footBall_body {
            padding: 0.2933rem 0.32rem 0;

            .footBall_day {
                background: #FAFBFF;
                box-shadow: 0px 0.0533rem 0.4266rem 0px rgba(224, 228, 246, 0.5);
                border-radius: 0.2133rem;
            }
        }

        .footBall_bottom {
            width: calc(100% - 0.64rem);
            padding: 0.2933rem 0.4rem;
            box-sizing: border-box;
            background: #FFFFFF;
            border-radius: 0.2133rem 0.2133rem 0px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            bottom:1.8rem;
            z-index: 999;
            left: 0.32rem;

            .footBall_bottom_left {
                display: flex;
                align-items: center;

                &>img {
                    width: 0.5333rem;
                }

                &>span {
                    margin-left: 0.32rem;
                    font-size: 0.32rem;
                    color: #383838;

                    .footBall_bottom_left_em1 {
                        color: #E2335D
                    }

                    .footBall_bottom_left_em2 {
                        color: #FF7671
                    }
                }
            }

            .footBall_bottom_right {
                padding: 0.16rem 0.6133rem;
                background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);
                border-radius: 0.4266rem;

                font-size: 0.3733rem;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 0.5333rem;
            }

            .footBall_bottom_right--disabled {
                background: #FFABA8;
            }
        }


    }
    .footBall_tips {
        padding: 0.2666rem 0.64rem;
        margin-bottom: 0.5333rem;
        font-size: 0.4266rem;
        font-weight: 400;
        color: #383838;
        line-height: 0.5866rem;
    }
    .dianz{
        width: 100%;
        height: 1.227rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .dianz_item{
            display: flex;
            align-items: center;
            img{
                width: 0.48rem;
                height: 0.48rem;
                margin-right: 0.107rem;
            }
            div{
                font-size: 0.373rem;
                font-weight: 400;
                color: #B4B4B4;
            }
        }
    }
    .basketball_bottom{
            width: calc(100% - 0.64rem);
            padding: 0.2933rem 0.4rem;
            box-sizing: border-box;
            background: #FFFFFF;
            border-radius: 0.2133rem 0.2133rem 0px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            bottom:1.8rem;
            z-index: 999;
            left: 0.32rem;

            .basketball_bottom_left {
                display: flex;
                align-items: center;

                &>img {
                    width: 0.5333rem;
                }

                &>span {
                    margin-left: 0.32rem;
                    font-size: 0.32rem;
                    color: #383838;

                    .footBall_bottom_left_em1 {
                        color: #E2335D
                    }

                    .footBall_bottom_left_em2 {
                        color: #FF7671
                    }
                }
            }

            .basketball_bottom_right {
                padding: 0.16rem 0.6133rem;
                background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);
                border-radius: 0.4266rem;

                font-size: 0.3733rem;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 0.5333rem;
            }

            .footBall_bottom_right--disabled {
                background: #FFABA8;
            }

    }
</style>
